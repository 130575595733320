/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
// FIXME: broken typescript
const { GoogleMap, useJsApiLoader } = require('@react-google-maps/api');
import { useCurrentPosition } from 'src/hooks/useCurrentPosition';
import { getLocalMerchantId } from 'components/settings/localStore';

interface Point {
    lat: number;
    lng: number;
}

export interface IPoint extends Point {
    markerName: string;
}

interface GMapProps {
    center: Point;
    width: number;
    className?: string;
    height?: number;
    markers?: IPoint[];
    options?: google.maps.MapOptions;
    zoom?: number;
    useCustomPin?: boolean;
    boundAll?: boolean;
    boundTo?: Point;
}

function fitMapBounds(lat: number, lng: number, width: number, map: google.maps.Map) {
    if (lat && lng && width && map) {
        map.fitBounds(
            new google.maps.LatLngBounds(
                new google.maps.LatLng(lat, lng - width / 2),
                new google.maps.LatLng(lat, lng + width / 2)
            )
        );
    }
}

const MAP_ID = 'Mui-google-map';
const GOOGLE_MAPS_LIBARARIES = ['marker'];

export const MuiMap: React.FC<GMapProps> = props => {
    const { center, width, className, height, boundAll, options, markers, useCustomPin, ...rest } = props;
    const { isLoaded } = useJsApiLoader({
        id: MAP_ID,
        googleMapsApiKey: process.env.GOOGLE_API_KEY,
        libraries: GOOGLE_MAPS_LIBARARIES
    });
    const { lat, lng } = center;
    const [map, setMap] = React.useState(null as null | google.maps.Map);
    const { position: devicePosition } = useCurrentPosition();
    const [isCustomPinAvailable, setIsCustomPinAvailable] = React.useState(false);
    const [customPinDimensions, setCustomPinDimensions] = React.useState([32, 32]);
    const merchantId = getLocalMerchantId();
    const customPinPath = React.useMemo(
        () => `${process.env.MEDIA_URL}/tenants/${merchantId}/app_assets/map_pin`,
        [merchantId]
    );
    React.useEffect(() => {
        if (map) {
            if (markers && boundAll) {
                const bounds = new google.maps.LatLngBounds();

                markers.map(marker => {
                    const position = new google.maps.LatLng(marker.lat, marker.lng);

                    bounds.extend(position);
                });
                map.fitBounds(bounds);
            } else if (props.boundTo) {
                const bounds = new google.maps.LatLngBounds();
                bounds.extend({ lat, lng });
                bounds.extend({ lat: props.boundTo.lat, lng: props.boundTo.lng });
                map.fitBounds(bounds);
            } else {
                fitMapBounds(lat, lng, width, map);
            }
        }
    }, [boundAll, lat, lng, map, markers, props.boundTo, width]);

    React.useEffect(() => {
        if (map && isLoaded) {
            // create markers
            if (devicePosition) {
                const currentLocationImg = document.createElement('img');
                currentLocationImg.src = 'current-location.svg';
                // eslint-disable-next-line no-new
                new google.maps.marker.AdvancedMarkerElement({
                    map,
                    position: { lat: devicePosition.coords.latitude, lng: devicePosition.coords.longitude },
                    content: currentLocationImg
                });
            }
            if (Array.isArray(markers)) {
                markers.forEach(item => {
                    let customMarkerImage;
                    if (useCustomPin && isCustomPinAvailable) {
                        customMarkerImage = document.createElement('img');
                        const [width, height] = customPinDimensions;
                        customMarkerImage.width = width;
                        customMarkerImage.height = height;
                        customMarkerImage.src = customPinPath;
                    }
                    // eslint-disable-next-line no-new
                    new google.maps.marker.AdvancedMarkerElement({
                        map,
                        position: { lat: item.lat, lng: item.lng },
                        content: customMarkerImage ?? new google.maps.marker.PinElement().element
                    });
                });
            }
        }
    }, [
        customPinPath,
        customPinDimensions,
        devicePosition,
        isCustomPinAvailable,
        isLoaded,
        map,
        markers,
        useCustomPin
    ]);

    const onMapLoad = React.useCallback(
        (loadedMap: google.maps.Map) => {
            setMap(loadedMap);
            fitMapBounds(lat, lng, width, loadedMap);
        },
        [lat, lng, width]
    );

    // Check if merchant has uploaded custom pin
    React.useEffect(() => {
        const img = new Image();
        img.onload = () => {
            const biggestDimension = img.width > img.height ? img.width : img.height;
            const scale = biggestDimension > 100 ? 45 / biggestDimension : 1;
            setCustomPinDimensions([img.width * scale, img.height * scale]);
            setIsCustomPinAvailable(true);
        };

        img.src = customPinPath;
    }, [customPinPath, merchantId]);

    const mapOptions: google.maps.MapOptions = {
        disableDefaultUI: false,
        draggable: true,
        gestureHandling: 'auto',
        fullscreenControl: false,
        mapId: MAP_ID,
        ...options
    };

    if (!isLoaded) {
        return null;
    }

    return (
        <GoogleMap
            id={MAP_ID}
            clickableIcons={false}
            center={center}
            onLoad={onMapLoad}
            mapContainerClassName={className}
            mapContainerStyle={{}}
            options={mapOptions}
            {...rest}
        >
            <div style={{ height: height || 'inherit', width: '100%' }} />
        </GoogleMap>
    );
};
