import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Box, createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { DESKTOP_COLORS } from 'src/consts';
import { usePagination } from 'src/hooks/usePagination';
import { useResponsive } from 'src/hooks/useResponsive';
import { MerchantTitle } from 'app/MerchantTitle';
import { getLocalJourney } from 'components/journey/localStore';
import { locationApi } from 'components/location/locationApi';
import { LocationsList } from 'components/location/LocationsList';
import { LocationsData } from 'components/location/model/Location';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { EmptyState } from 'lib/EmptyState';
import useDebounce from 'lib/helpers';
import logger from 'lib/logger';
import { IPoint, MuiMap } from 'lib/MuiMap';
import { ResponsiveLayout } from 'lib/Responsive';
import { SearchInput } from 'lib/SearchInput';
import { Throbber } from 'lib/Throbber';
import { isNumber } from 'lib/typeInference';
import { useLocalHistory } from 'lib/useLocalHistory';
import { getCoordinatesSuccess, getLocations, GetLocationsOptions } from 'store/locations/locationActions';
import { ApplicationState } from 'store/store';
import { BaseRouteParams } from './routes';
import { Image } from 'lib/Image';

function geoFindMe(onSuccess: (lat: number, lng: number) => void, onError: () => void) {
    function success(position: GeolocationPosition) {
        const { latitude, longitude } = position.coords;
        onSuccess(latitude, longitude);
    }

    if (!navigator.geolocation) {
        console.warn('Geolocation is not supported by your browser');
    } else {
        navigator.geolocation.getCurrentPosition(success, onError);
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        map: (props: { isDesktop: boolean }) => ({
            width: '100%',
            height: props.isDesktop ? '100%' : '35vh',
            flexShrink: 0
        }),
        container: (props: { isDesktop: boolean }) => ({
            overflowY: 'hidden',
            height: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            ...(props.isDesktop
                ? {
                      backgroundColor: theme.palette.background.default
                  }
                : {})
        }),
        locationsList: {
            flex: 1,
            overflowY: 'auto',
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'auto',
                border: 'none'
            },
            '&::-webkit-scrollbar': {
                width: '2px',
                borderRight: '2px solid transparent'
            }
        },
        desktopCard: {
            width: '100%',
            height: 0,
            margin: 'auto',
            padding: theme.spacing(4.5, 0, 4.5, 0),
            flex: 1
        },
        desktopLocationsWrapper: {
            height: '100%',
            overflow: 'hidden',
            backgroundColor: DESKTOP_COLORS.LOCATIONS_BACKGROUND,
            '-webkit-mask-image': '-webkit-radial-gradient(white, black);'
        },
        cardShadow: {
            boxShadow: theme.shadows[4],
            borderRadius: theme.shape.borderRadius,
            height: '100%'
        },
        fullHeight: {
            height: '100%'
        },
        locationsTitle: (props: { isDesktop: boolean }) => ({
            fontFamily: `TenantFont, ${theme.typography.fontFamily}`,
            fontWeight: 'bold',
            fontSize: props.isDesktop ? theme.spacing(2.25) : theme.spacing(2.5),
            color: DESKTOP_COLORS.TEXT_PRIMARY
        }),
        locationDescription: {
            color: DESKTOP_COLORS.TEXT_PRIMARY,
            whiteSpace: 'pre-wrap'
        },
        searchInputBox: {
            padding: theme.spacing(1)
        },
        searchWrapper: {
            border: '1px solid rgba(0,0,0,0.3)',
            backgroundColor: theme.palette.common.white,
            color: DESKTOP_COLORS.TEXT_PRIMARY
        },
        searchInput: {
            backgroundColor: theme.palette.common.white,
            color: DESKTOP_COLORS.TEXT_PRIMARY,
            '&::-webkit-input-placeholder': {
                color: DESKTOP_COLORS.TEXT_SECONDARY
            }
        },
        searchInputIcon: {
            color: DESKTOP_COLORS.TEXT_PRIMARY
        },
        locationsCard: {
            height: '100%',
            display: 'flex',
            overflowY: 'scroll',
            flexDirection: 'column',
            '&::-webkit-scrollbar': {
                display: 'none'
            }
        }
    })
);

export const LocationsPage: React.FC = () => {
    const dispatch = useDispatch();
    const { locations, isLoading, summary, lat, lng } = useSelector(
        (state: ApplicationState) => state.locations
    );
    const { isDesktop } = useResponsive();
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const classes = useStyles({ isDesktop });
    const { merchantId } = useParams<BaseRouteParams>();
    const { push } = useLocalHistory();
    const { pathname } = useLocation();
    const [showLogo, setShowLogo] = React.useState<boolean>(true);
    const hasGeoLocation = React.useMemo(() => lat && lng, [lat, lng]);

    React.useEffect(() => {
        // Return user to where they were, if root page
        // This page is used when the iframe is visited, and therefore
        // should return the user to their previous location if before 24hrs
        if (merchantId) {
            const localJourney = getLocalJourney(merchantId);
            if (localJourney && localJourney.url && pathname !== localJourney.url) {
                push(localJourney.url);
            }
        }
    }, [push, merchantId, pathname]);

    React.useEffect(() => {
        geoFindMe(
            (lattitude, longtitude) => {
                getCoordinatesSuccess(lattitude, longtitude)(dispatch);
            },
            () => {
                logger.warn('error during accessing coords');
            }
        );
    }, [dispatch]);

    // Debounce the search input so we do not spam the locations api
    // while the user might still be typing.
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    React.useEffect(() => {
        const getLocationOptions: GetLocationsOptions = hasGeoLocation
            ? {
                  coords: { lat: lat || 0, long: lng || 0 }
              }
            : {};
        if (debouncedSearchTerm !== '') {
            getLocationOptions.searchTerm = debouncedSearchTerm;
        }
        getLocations(getLocationOptions)(dispatch);
    }, [debouncedSearchTerm, dispatch, hasGeoLocation, lat, lng]);

    const mapCenter = React.useMemo(() => {
        if (lat && lng) {
            return { lat, lng };
        }
        return locations && locations[0] && locations[0].geo
            ? { lat: locations[0].geo[1], lng: locations[0].geo[0] }
            : { lat: 0, lng: 0 };
    }, [locations, lat, lng]);

    const handleSearchChange = React.useCallback((e: React.ChangeEvent<{ value: any }>) => {
        setSearchTerm(e.target.value);
    }, []);
    const clearInput = React.useCallback(() => {
        setSearchTerm('');
    }, []);

    const locationPoints: IPoint[] =
        locations.length === 0
            ? []
            : locations.map(location => ({
                  lat: location.geo[1],
                  lng: location.geo[0],
                  markerName: location.title
              }));
    const handleLogoFallbackError = React.useCallback(() => {
        setShowLogo(false);
    }, []);
    const renderMerchantTitle = React.useMemo(() => {
        if (showLogo) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Image
                        src={`${process.env.MEDIA_URL}/tenants/${merchantId}/app_assets/web_icon`}
                        onError={handleLogoFallbackError}
                        height="26px"
                    />
                </Box>
            );
        }
        return t('LOCATIONS_TITLE');
    }, [handleLogoFallbackError, merchantId, showLogo, t]);
    const {
        isLoading: isPaginationLoading,
        loadMoreItems,
        hasNext
    } = usePagination<LocationsData>({
        perPage: 25,
        initialState: summary
    });

    const handleLoadMoreItems = React.useCallback(
        () =>
            loadMoreItems(params => {
                const queryParams = { ...params };
                if (isNumber(lat) && isNumber(lng)) {
                    queryParams.lat = lat.toString();
                    queryParams.long = lng.toString();
                }
                return locationApi.getList({ ...queryParams });
            }),
        [lat, lng, loadMoreItems]
    );

    const boundTo = React.useMemo(
        () =>
            hasGeoLocation && locations[0]
                ? { lat: locations[0]?.geo[1], lng: locations[0]?.geo[0] }
                : undefined,
        [hasGeoLocation, locations]
    );

    return (
        <ResponsiveLayout
            renderMobileView={
                <Box className={classes.container}>
                    <MerchantTitle title={t('LOCATIONS_TITLE')} />
                    <MuiAppBar disableBack title={renderMerchantTitle} />
                    <MuiMap
                        width={0}
                        center={mapCenter}
                        className={classes.map}
                        options={{ keyboardShortcuts: false, disableDefaultUI: true }}
                        markers={locationPoints}
                        useCustomPin
                        boundAll={!hasGeoLocation}
                        boundTo={boundTo}
                    />
                    <SearchInput
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onClear={clearInput}
                        placeholder={t('LOCATIONS_SEARCHBAR')}
                        componentClasses={{
                            box: classes.searchInputBox
                        }}
                    />
                    {isLoading && <Throbber text={t('GENERAL_LOADING')} />}
                    {!isLoading && locations.length > 0 && (
                        <Box className={classes.locationsList}>
                            <LocationsList
                                loadMore={handleLoadMoreItems}
                                isLoading={isPaginationLoading}
                                hasNext={hasNext}
                                list={locations}
                                hasGeoLocation={!!hasGeoLocation}
                            />
                        </Box>
                    )}
                    {!isLoading && locations.length === 0 && (
                        <EmptyState
                            headerText={t('GENERAL_GENERIC_ERROR')}
                            paragraphText={t('LOCATION_NO_LOCATIONS_SEARCH')}
                        >
                            <Search style={{ fontSize: 80 }} />
                        </EmptyState>
                    )}
                </Box>
            }
            renderDesktopView={
                <Box display="flex" flexDirection="column" height="100%">
                    <MerchantTitle title={t('LOCATIONS_TITLE')} />
                    <MuiAppBar disableBack title={renderMerchantTitle} />
                    <Box className={classes.desktopCard}>
                        <div className={classes.cardShadow}>
                            <Paper className={classes.desktopLocationsWrapper}>
                                <Grid container spacing={0} className={classes.fullHeight}>
                                    <Grid item xs={6} className={classes.fullHeight}>
                                        <Box className={classes.locationsCard}>
                                            <Box paddingX={5.5} paddingTop={4} textAlign="center">
                                                <Typography variant="h6" className={classes.locationsTitle}>
                                                    {t('LOCATIONS_SELECT_TITLE')}
                                                </Typography>
                                                <Typography
                                                    className={classes.locationDescription}
                                                    variant="body1"
                                                >
                                                    {t('LOCATIONS_PAGE_DESCRIPTION')}
                                                </Typography>
                                            </Box>
                                            <Box paddingX={12.5} marginTop={2.5}>
                                                <SearchInput
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                    onClear={clearInput}
                                                    placeholder={t('LOCATIONS_SEARCHBAR')}
                                                    componentClasses={{
                                                        input: classes.searchInput,
                                                        icon: classes.searchInputIcon,
                                                        wrapper: classes.searchWrapper,
                                                        box: classes.searchInputBox
                                                    }}
                                                />
                                            </Box>
                                            {!isLoading && locations.length > 0 && (
                                                <Box paddingX={1}>
                                                    <LocationsList
                                                        loadMore={handleLoadMoreItems}
                                                        isLoading={isPaginationLoading}
                                                        hasNext={hasNext}
                                                        list={locations}
                                                        hasGeoLocation={!!hasGeoLocation}
                                                    />
                                                </Box>
                                            )}
                                            {!isLoading && locations.length === 0 && (
                                                <EmptyState
                                                    headerText={t('GENERAL_GENERIC_ERROR')}
                                                    paragraphText={t('LOCATION_NO_LOCATIONS_SEARCH')}
                                                >
                                                    <Search style={{ fontSize: 80 }} />
                                                </EmptyState>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} className={classes.fullHeight}>
                                        <MuiMap
                                            width={0}
                                            center={mapCenter}
                                            className={classes.map}
                                            options={{
                                                keyboardShortcuts: false,
                                                disableDefaultUI: true,
                                                zoomControl: true
                                            }}
                                            markers={locationPoints}
                                            useCustomPin
                                            boundAll={!hasGeoLocation}
                                            boundTo={boundTo}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </Box>
                </Box>
            }
        />
    );
};
