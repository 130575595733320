import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, Dialog, Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useAsyncData } from 'src/hooks/useAsyncData';
import { isLoading, isSuccess } from 'src/utils/request';
import { MerchantTitle } from 'app/MerchantTitle';
import { awardsApi } from 'components/awards/awardsApi';
import { IAwardsResponse } from 'components/awards/models';
import { AwardsList } from 'components/awards/ui/List';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { ELoyaltyProvider } from 'components/awards/enums';
// FIXME: replcae with qr code icon once upgraded to mui v5
import VisibilityIcon from '@material-ui/icons/Visibility';
import { AwardUserSection } from 'components/awards/ui/user-section/AwardUserSection';
import { EGAEventName, useGAHelpers } from 'lib/useGAHelpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loadingSkeleton: {
            borderRadius: theme.shape.borderRadius
        }
    })
);

export const AwardsPage: React.FC = () => {
    const { t } = useTranslation();
    const [awardsResponse, setAwardsResponse] = useAsyncData<IAwardsResponse>();
    const [showBarcodeDialog, setShowBarcodeDialog] = React.useState(false);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const classes = useStyles();

    React.useEffect(() => {
        if (settings) {
            setAwardsResponse(() =>
                awardsApi.getAwards(
                    settings?.loyaltyProvider === ELoyaltyProvider.Square ||
                        settings?.loyaltyProvider === ELoyaltyProvider.Powercard
                )
            );
        }
    }, [setAwardsResponse, settings]);

    const loadingSkeleton = React.useMemo(() => {
        const skeletonArray = [];
        for (let i = 0; i < 8; i++) {
            skeletonArray.push(
                <Grid item key={i} xs={12} sm={4} md={3}>
                    <Skeleton
                        className={classes.loadingSkeleton}
                        variant="rect"
                        animation="wave"
                        height={200}
                    />
                </Grid>
            );
        }

        return (
            <Grid container spacing={1}>
                {skeletonArray}
            </Grid>
        );
    }, [classes.loadingSkeleton]);

    const { logUserEvent } = useGAHelpers();

    const barcodeIconEnabled =
        settings?.offlineCheckinEnabled && settings?.app?.awardsScreenBarcodePosition === 'TOOLBAR_BUTTON';
    const handleBarcodeIconClick = React.useCallback(() => {
        logUserEvent(EGAEventName.ShowBarcodeIconTap);
        setShowBarcodeDialog(true);
    }, [logUserEvent]);
    const handleBarcodeDialogClose = React.useCallback(() => {
        setShowBarcodeDialog(false);
    }, []);

    return (
        <>
            <MerchantTitle title={t('AWARDS_TITLE')} />
            <MuiAppBar title={t('AWARDS_TITLE')}>
                {barcodeIconEnabled && (
                    <IconButton onClick={handleBarcodeIconClick}>
                        <VisibilityIcon color="primary" />
                    </IconButton>
                )}
            </MuiAppBar>
            {isSuccess(awardsResponse) && awardsResponse.data && (
                <AwardsList awards={awardsResponse.data.awards} />
            )}
            {isLoading(awardsResponse) && <Box padding={2}>{loadingSkeleton}</Box>}
            {barcodeIconEnabled && (
                <Dialog fullWidth open={showBarcodeDialog} onClose={handleBarcodeDialogClose}>
                    <Box padding={2}>
                        <AwardUserSection />
                    </Box>
                </Dialog>
            )}
        </>
    );
};
