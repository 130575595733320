import { Box } from '@material-ui/core';
import React from 'react';
import { AddToWalletButton } from './AddToWalletButton';
import { QrCode } from 'components/awards/qr-code/QrCode';

export const AwardUserSection: React.FC = () => (
    <Box display="flex" flexDirection="column" width="100%" alignItems="center">
        <QrCode showTitle />
        <AddToWalletButton />
    </Box>
);
