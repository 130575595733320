import logger from 'lib/logger';
import { BaseRouteParams } from 'pages/routes';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from './useAuth';
import { isString } from './typeInference';
import { IBasketItem } from 'components/basket/model/Basket';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export const SWEETWATERS_ID = '5cb700b08c320545c7162a4a';
export const SWEETWATERS_CONTAINER = 'GTM-MT3HSR3';
const HIBSV4_ID = '5d0b7fc440c9f91be725a382';
export const TORTILLA_ID = '66321791553973966bcef686';
export const TORTILLA_CONTAINER = 'GTM-NNWDZSS';

export enum EGAEventName {
    AddToOrder = 'add_to_order',
    AddMoreItems = 'open_mpo',
    ModifierHeaderTapped = 'modifier_header_tapped',
    AddToBasketSweetwaters = 'add_to_basket_sweetwaters',
    ViewBasketSweetwaters = 'view_basket_sweetwaters',
    SubmitOrderSweetwaters = 'submit_order_sweetwaters',
    AddFromRecentOrdersSweetwaters = 'add_from_recent_orders_sweetwaters',
    QuickAddRecentOrdersSweetwaters = 'quick_add_recent_orders_sweetwaters',
    AddToGoogleWalletButtonTap = 'add_to_google_wallet_button_tap',
    AddToAppleWalletButtonTap = 'add_to_apple_wallet_button_tap',
    BackIconClick = 'back_icon_click',
    CustomCloseIconClick = 'custom_close_icon_click',
    AddToBasketDetailed = 'add_to_cart',
    ViewBasketDetailed = 'view_cart',
    PlaceOrderDetailed = 'purchase',
    CookiesAcceptAll = 'cookies_accept_all',
    CookiesRejectAll = 'cookies_reject_all',
    CookiesManage = 'cookies_manage',
    CookiesSave = 'cookies_save',
    GuestAccountExistCancel = 'guest_account_exist_cancel',
    GuestAccountExistLogin = 'guest_account_exist_login',
    LoginSubmit = 'login_submit',
    ForgotPasswordClick = 'forgot_password_click',
    ResetPasswordSubmit = 'reset_password_submit',
    ResetPasswordBack = 'reset_password_back',
    CreatePasswordSubmit = 'create_password_submit',
    CreatePasswordBack = 'create_password_back',
    ShowBarcodeIconTap = 'show_barcode_icon_tap'
}

export function useGAHelpers() {
    const { menu } = useSelector((state: ApplicationState) => state.menu);
    const region = useSelector((state: ApplicationState) => state.settings.settings?.region);
    const { merchantId } = useParams<BaseRouteParams>();
    const { user } = useAuth();
    const logUserEvent = React.useCallback(
        (eventName: string, data: any = {}) => {
            try {
                if (window.gtag) {
                    window.gtag('event', eventName, {
                        user: user?._id,
                        merchantId,
                        ...data
                    });
                } else {
                    logger.warn('No GTAG');
                }
            } catch (e) {
                logger.error(e.message, e);
            }
        },
        [merchantId, user?._id]
    );
    const getBasketEventBody = React.useCallback(
        (items: (IBasketItem & { price: number })[], locationId: string) => {
            const value = items.reduce((acc, item) => acc + item.price, 0);
            return {
                currency: region?.currencyCode,
                value,
                items: items.map(item => ({
                    item_id: item.productId,
                    item_name: menu?.productIdToProduct.get(item.productId)?.title,
                    item_category: item.categoryId,
                    quantity: item.quantity,
                    price: item.price,
                    locationId
                }))
            };
        },
        [menu?.productIdToProduct, region?.currencyCode]
    );
    return { logUserEvent, getBasketEventBody };
}

export const isSweetwaters = (merchantId?: string) => isString(merchantId) && merchantId === SWEETWATERS_ID;
export const isHIBSV4 = (merchantId?: string) => isString(merchantId) && merchantId === HIBSV4_ID;
export const isTortilla = (merchantId?: string) => isString(merchantId) && merchantId === TORTILLA_ID;

// We don't support all at the moment
export const updateGtagConsent = (variant: 'all' | 'none' | 'analyticOnly') => {
    if (window.gtag) {
        const body = {
            ad_user_data: variant === 'all' ? 'granted' : 'denied',
            ad_personalization: variant === 'all' ? 'granted' : 'denied',
            ad_storage: variant === 'all' ? 'granted' : 'denied',
            analytics_storage: variant === 'all' || variant === 'analyticOnly' ? 'granted' : 'denied'
        };
        window.gtag('consent', 'update', body);
    } else {
        logger.warn('no GTAG');
    }
};
