import { Box } from '@material-ui/core';
import { Image } from 'lib/Image';
import { LocationRouteParams } from 'pages/routes';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ApplicationState } from 'store/store';

export function useMerchantLogo() {
    const { merchantId } = useParams<LocationRouteParams>();
    const settings = useSelector((state: ApplicationState) => state.settings);
    const [showTitle, setShowTitle] = React.useState(false);

    const onLogoError = React.useCallback(() => {
        setShowTitle(true);
    }, []);

    const renderMerchantTitle = React.useMemo(() => {
        if (showTitle) {
            return settings.settings?.title;
        }
        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                <Image
                    src={`${process.env.MEDIA_URL}/tenants/${merchantId}/app_assets/web_icon`}
                    onFallbackError={onLogoError}
                    height="26px"
                    alt={settings.settings?.title}
                />
            </Box>
        );
    }, [merchantId, onLogoError, settings.settings?.title, showTitle]);
    return renderMerchantTitle;
}
