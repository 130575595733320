import { HttpClient } from 'components/http/HttpClient';
import { getLocalSettings } from 'components/settings/localStore';
import { extendMenuWithModifierMaps, IEnrichedMenuWithModifierMaps } from './model/Menu';

class MenuApi extends HttpClient {
    constructor() {
        super({
            baseURL: process.env.MENU_SERVICE_URL,
            url: '/menu'
        });
    }

    public async getMenu(params?: Record<string, string>): Promise<IEnrichedMenuWithModifierMaps> {
        const localSettings = getLocalSettings();
        if (!localSettings) {
            throw new Error('merchantId and x-application-id are missed');
        }
        const data = await this.getRequest<IEnrichedMenuWithModifierMaps>({
            url: '',
            headerConfigs: { shouldIncludeAuthToken: true },
            headers: { 'x-api-version': 6 },
            configs: { params: { ...params, tenantId: localSettings.merchantId } }
        });

        return extendMenuWithModifierMaps(data);
    }
}

export const menuApi = new MenuApi();
