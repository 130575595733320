import React from 'react';
import { Link } from 'react-router-dom';

const betaMerchants = [
    {
        title: 'Youngs on Tap',
        id: '5ae6e116954e893e59baa5be'
    },
    {
        title: 'Hooli IBS V4',
        id: '5d0b7fc440c9f91be725a382'
    },
    {
        id: '5c2a2e93e101e063d09eb1d0',
        title: 'Hooli Square 1'
    },
    {
        id: '5afaf0590863fa68704a2c7d',
        title: 'Hooli Square'
    },
    {
        id: '5d14c5e240c9f91be725a389',
        title: 'Revolution Beta'
    },
    {
        id: '5d6d03d71890840c8a21b702',
        title: 'Hooli Micros'
    },
    {
        id: '61e82078ff46ad0d4b642b7a',
        title: 'Quick Pay(Hooli QA IBS)'
    },
    {
        id: '60cc86193b96914f3007fda9',
        title: 'Hooli Worldpay'
    },
    {
        id: '60a3c3b8bd84370dda0709ee',
        title: 'Hooli Stripe'
    },
    {
        id: '5c2a2ed1e101e063d09eb1d6',
        title: 'Hooli Square 2'
    },
    {
        id: '5ee3ad9a3e6a150c836a96fd',
        title: 'Hooli Aloha'
    },
    {
        id: '5e6f5cbdebd53b0db5cf53ce',
        title: 'Hooli Revel V4'
    },
    {
        id: '5eaff9bfc429910c921bde54',
        title: 'Hooli Zonal'
    },
    {
        id: '5dd2812cdb08ac0dabf8f226',
        title: 'Hooli Comtrex'
    },
    {
        id: '6130a260293b6e0dd8ca1328',
        title: 'Hooli Deliverect'
    },
    {
        id: '66b4914a27c6d7f31b339762',
        title: 'Hooli Micros Simphony'
    },
    {
        id: '62bb093b61f87babccb42119',
        title: 'Hooli Goodtill'
    }
];

export const LandingPage = () => {
    if (process.env.TIER !== 'beta') {
        window.location.href = 'https://pepperhq.com';
        return null;
    }
    return (
        <div>
            {betaMerchants.map(({ title, id }) => (
                <div key={`merchantLink-${id}`}>
                    <Link to={`/${id}`}>{title}</Link>
                </div>
            ))}
        </div>
    );
};
