import axios from 'axios';

export const getLoadingImagesFolder = (merchantId: string) =>
    `${process.env.MEDIA_URL}/tenants/${merchantId}/app_assets`;

export async function preloadImage(link: string) {
    try {
        await axios.head(link);
        return link;
    } catch (e) {
        return undefined;
    }
}
