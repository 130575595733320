import {
    ELoyaltyDiscountChildItemRule,
    ELoyaltyDiscountRule,
    ELoyaltyDiscountType,
    ELoyaltyProvider,
    ELoyaltyScope,
    ELoyaltyTemplate,
    EPerkRedemptionMethod
} from './enums';

interface INameValuePair {
    name: string;
    value: string;
}

export interface IPerkDateTimeRestrictions {
    startTime?: number;
    endTime?: number;
    daysOfWeek?: number;
    startDate?: Date;
    endDate?: Date;
}

interface IPerkBasketQualification {
    matchCode?: string;
    minimumItems?: number;
    minimumValue?: number;
    scope?: ELoyaltyScope;
}

interface IPerkDiscount {
    value: number;
    type: ELoyaltyDiscountType;
    maxValue?: number;
    scope: ELoyaltyScope;
    scopeMatchCode?: string;
    rule: ELoyaltyDiscountRule;
    childItemRule: ELoyaltyDiscountChildItemRule;
}

export enum EAwardsScreenShape {
    DOUBLE_RECTANGLE = 'DOUBLE_RECTANGLE',
    SINGLE_RECTANGLE = 'SINGLE_RECTANGLE',
    LARGE_SQUARE = 'LARGE_SQUARE',
    SMALL_SQUARE = 'SMALL_SQUARE'
}

export interface IAwardVisualization {
    templates: INameValuePair[];
    media: INameValuePair[];
    colours: INameValuePair[];
    stampImageUrl?: string;
    textColour?: string;
    stampColour?: string;
    randomiseStampPositions?: boolean;
    backgroundOverlayColour?: string;
    backgroundOverlayOpacity?: number;
    subtitleText?: string;
    description?: string;
    availableCardCopy?: string;
    nextCardProgressCopy?: string;
    textAlignment?: 'LEFT' | 'CENTER' | 'RIGHT';
    subtitleTwoText?: string;
    verticalAlignment?: 'TOP' | 'CENTER' | 'BOTTOM';
    backgroundImageUrl?: string;
    titleText?: string;
    awardsScreenShape?: EAwardsScreenShape;
}

export interface IPointAward {
    _id: string;
    tenantId: string;
    loyaltyProvider: ELoyaltyProvider;
    perkId: string;
    userId: string;
    title: string;
    template: ELoyaltyTemplate;
    redemptionMethod: EPerkRedemptionMethod;
    points: {
        initial: number;
        min: number;
        max: number;
        step: number;
        redemption: number;
        available: number;
    };
    maximumPointCashValue: number;
    token: string;
    isGlobal: boolean;
    isTier: boolean;
    isDonatable: boolean;
    auto?: boolean;
    terms: string;
    priority: number;
    discount: IPerkDiscount;
    dateTimeRestriction?: IPerkDateTimeRestrictions;
    basketQualification?: IPerkBasketQualification;
    visualisation: IAwardVisualization;
}

export interface IAwardsResponse {
    awards: IPointAward[];
}
