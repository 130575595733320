import { IItemModifierResourceV10, IItemReadResourceV10 } from 'components/basket/model/Basket';
import { isDefined, isEmptyString } from 'lib/typeInference';

interface IModifierSettings {
    id: string;
    min: number;
    max: number;
    title: string;
    bundle: boolean;
    allowQuantitySelect?: boolean;
    isCollapsed?: boolean;
}

export interface IProductModifierSettings extends IModifierSettings {
    products: IEnrichedProduct[];
}

export interface IOptionModifierSettingsOption {
    title: string;
    price: number;
    id: string;
    imageUrl?: string;
    selected?: boolean;
    nutrition?: INutritionMenuEnrichmentFields[];
    available?: boolean; // Unsure of what the 'default' state is when not defined
}

export interface IOptionModifierSettings extends IModifierSettings {
    options: IOptionModifierSettingsOption[];
    sort: number;
}

export interface INutritionMenuEnrichment {
    nutrient: string;
    value: string;
}

export interface INutritionMenuEnrichmentFields {
    title: string;
    values: INutritionMenuEnrichment[];
}

export enum ESuggestionType {
    ADD = 'add',
    BOGOF = 'bogof'
}

export interface ISuggestions {
    description?: string;
    imageUrl?: string;
    productId?: string;
    title: string;
    type: string;
}

export interface IEnrichedOptions extends IOptions {
    allergens?: { id: string; title: string }[];
    taxes: IEnrichedTax[];
}

export interface IEnrichedTax extends ITax {
    applyForScenario?: string[];
}

export interface IEnrichedProduct extends IProduct {
    tags: IProductTagValue[];
    noDefault?: boolean;
}

export type IEnrichedCategory = ICategory & { products: IEnrichedProduct[]; groupCategoryId?: string };

export enum ECategoryGroupDisplayCode {
    HIDE = 'HIDE',
    SMALL = 'SMALL',
    LARGE = 'LARGE'
}

export interface ICategoryGroup {
    categoryIds: string[];
    id: string;
    shortDescription: string;
    sort: number;
    title: string;
    imageUrl?: string;
    displayCode?: ECategoryGroupDisplayCode;
}

export interface IEnrichedMenu extends IRawLocationMenu {
    version: number;
    schema: string;
    options: IEnrichedOptions;
    currency: 'GBP' | 'EUR' | 'USD';
    categories: IEnrichedCategory[];
    categoryGroups?: Record<number, ICategoryGroup>;
    modifiers?: IModifier[];
}

export interface IRawLocationMenu extends IRawMenu {
    tenantId: string;
    locationId: string;
    title: string;
    description: string;
    updated: string;
}

export interface IRawMenu {
    // as per all IDs in our mapped menu, this is an external POS ID.  Not all POS have a menuId
    id?: string;
    options: IOptions;
    categories: ICategory[];
    bundles: any[];
}

export interface IOptions {
    taxes: ITax[];
    tags: IProductTag[];
}

export interface ICategory {
    id: string;
    title: string;
    shortDescription: string;
    modifiers: IModifier[];
    products: IProduct[];
    perks?: string[];
    sort: number;
    collapsed: boolean;
    complements: any[];
    taxScheme?: string;
    zones?: string[];
    imageUrl?: string;
    visible: boolean;
    hideUnavailableProducts?: boolean;
}

export enum EDynamicImageType {
    BOX = 'BOX',
    LAYER = 'LAYER'
}

export interface IDynamicImage {
    imageUrl: string;
    sort: number;
}

export interface IDynamicContainer {
    suitableFor: string[];
    x: number;
    y: number;
    height: number;
    width: number;
    count: number;
    division?: 'HORIZONTAL' | 'VERTICAL' | 'LAYERED';
}

export interface IDynamicImage {
    type: EDynamicImageType.BOX;
    baseImageUrl: string;
    containers: IDynamicContainer[];
    images: Record<string, IDynamicImage>;
}

export interface IDynamicLayerContainer {
    suitableFor: string[];
    x?: number;
    y?: number;
    height?: number;
    width?: number;
    count: number;
}

export interface IDynamicLayerImage {
    type: EDynamicImageType.LAYER;
    baseImageUrl: string;
    containers: IDynamicLayerContainer[];
    images: Record<string, IDynamicImage>;
}

export function isBoxImagery(item: any): item is IDynamicImage {
    return item && item.type === EDynamicImageType.BOX;
}

export function isLayerImagery(item: any): item is IDynamicImage {
    return item && item.type === EDynamicImageType.LAYER;
}

export interface IProductTagValue {
    id: string;
    title: string;
    imageUrl?: string;
    sort?: number;
}

export interface IProductTag {
    id: string;
    sort?: number;
    title?: string;
    isFilter?: boolean;
    values: IProductTagValue[];
}

export interface IProduct {
    suggestionImage: any;
    id: string;
    title: string;
    shortDescription: string;
    price: number;
    taxIds: string[];
    perks?: string[];
    modifiers?: string[];
    sort: number;
    complements: any[];
    suggestions?: ISuggestions[];
    nutrition: INutritionMenuEnrichmentFields[];
    tags: any[];
    allergens?: string[];
    imageUrl?: string;
    prepTimeMins?: number;
    modifierImage?: string;
    listingImageUrl?: string;
    available?: boolean;
    productGroupId?: string;
    productGroupName?: string;
    productGroupDefault?: boolean;
    longDescription?: string;
    selected?: boolean;
    dynamicImagery?: IDynamicImage;
    listingBackgroundColour?: string;
    listingBackgroundImageUrl?: string;
    listingTextColour?: string;
}

export interface IModifier {
    id: string;
    title: string;
    minSelect: number;
    maxSelect: number;
    selected: any[];
    sort: number;
    bundle?: boolean;
    options?: IModifierOption[];
    products?: IModifierProduct[];
    allowQuantitySelect?: boolean;
    isCollapsed?: boolean;
}

export interface IModifierOption {
    id: string;
    sort: number;
    title: string;
    price: number;
    selected: boolean;
    available?: boolean;
    imageUrl?: string;
}

export interface IModifierProduct {
    id: string;
    sort: number;
    selected?: boolean;
    title?: string;
    imageUrl?: string;
}

export interface IAllergenList {
    celery?: boolean;
    gluten?: boolean;
    crustaceans?: boolean;
    eggs?: boolean;
    fish?: boolean;
    lupin?: boolean;
    milk?: boolean;
    molluscs?: boolean;
    mustard?: boolean;
    nuts?: boolean;
    peanuts?: boolean;
    sesameSeeds?: boolean;
    soya?: boolean;
    sulphurDioxide?: boolean;
}

export const isAllergenList = (list: any): list is IAllergenList =>
    list &&
    (isDefined(list.celery) ||
        isDefined(list.gluten) ||
        isDefined(list.crustaceans) ||
        isDefined(list.eggs) ||
        isDefined(list.fish) ||
        isDefined(list.lupin) ||
        isDefined(list.milk) ||
        isDefined(list.molluscs) ||
        isDefined(list.mustard) ||
        isDefined(list.nuts) ||
        isDefined(list.peanuts) ||
        isDefined(list.sesameSeeds) ||
        isDefined(list.soya) ||
        isDefined(list.sulphurDioxide));

export interface ITax {
    description: string;
    inclusive: boolean;
    id: string;
    title: string;
    rate: number; // TODO what is this rate ??  Assume 0..1.0
}

export enum DefaultVariantStrategy {
    LOWEST_PRICE = 'LOWEST_PRICE',
    HIGHEST_PRICE = 'HIGHEST_PRICE',
    NONE = 'NONE'
}

export interface ProductGroup {
    products: IEnrichedProduct[];
    id: string;
    title: string;
    sort: number;
}

export function isProductGroup(item: any): item is ProductGroup {
    return !!item && Array.isArray(item.products) && item.products.length;
}

export function isProductAvailable(product: IEnrichedProduct) {
    return !isDefined(product.available) || product.available;
}

export interface IEnrichedMenuWithModifierMaps extends IEnrichedMenu {
    productIdToCategoryId: Map<string, string>;
    productIdToProduct: Map<string, IEnrichedProduct>;
    modifierIdToProductModifiers: Map<string, IModifierProduct[]>;
    modifierIdToProductIds: Map<string, string[]>;
    modifierIdToProductModifierSettings: Map<string, IProductModifierSettings>;
    modifierIdToCategoryOptionModifierSettings: Map<string, Map<string, IOptionModifierSettings>>;
    modifierIdToOptionModifierSettings: Map<string, IOptionModifierSettings>;
}

export function extendMenuWithModifierMaps(menu: IEnrichedMenu) {
    const productIdToProduct: Map<string, IEnrichedProduct> = new Map();
    const modifierIdToProductIds: Map<string, string[]> = new Map();
    const modifierIdToProductModifierSettings: Map<string, IProductModifierSettings> = new Map();
    const modifierIdToProductModifiers: Map<string, IModifierProduct[]> = new Map();
    const productIdToCategoryId: Map<string, string> = new Map();
    const modifierIdToCategoryOptionModifierSettings: Map<
        string,
        Map<string, IOptionModifierSettings>
    > = new Map();

    const modifierIdToOptionModifierSettings: Map<string, IOptionModifierSettings> = new Map();
    if (menu.modifiers) {
        for (const modifier of menu.modifiers) {
            if (modifier.products && modifier.products.length > 0) {
                modifierIdToProductModifierSettings.set(modifier.id, {
                    id: modifier.id,
                    min: modifier.minSelect,
                    max: modifier.maxSelect,
                    products: [],
                    title: modifier.title,
                    bundle: !!modifier.bundle,
                    allowQuantitySelect: !!modifier.allowQuantitySelect,
                    isCollapsed: !!modifier.isCollapsed
                });
                modifierIdToProductIds.set(
                    modifier.id,
                    modifier.products.map(modProd => modProd.id)
                );
                modifierIdToProductModifiers.set(modifier.id, modifier.products);
            }
            if (modifier.options && modifier.options.length > 0) {
                modifierIdToOptionModifierSettings.set(modifier.id, {
                    id: modifier.id,
                    min: modifier.minSelect,
                    max: modifier.maxSelect,
                    options: modifier.options,
                    title: modifier.title,
                    bundle: !!modifier.bundle,
                    allowQuantitySelect: !!modifier.allowQuantitySelect,
                    isCollapsed: !!modifier.isCollapsed,
                    sort: modifier.sort
                });
                modifierIdToProductIds.set(
                    modifier.id,
                    modifier.options.map(modProd => modProd.id)
                );
            }
        }
    }

    for (const category of menu.categories) {
        const modifierIdToOptionModifierSettings: Map<string, IOptionModifierSettings> = new Map();
        for (const product of category.products) {
            if (isProductGroup(product)) {
                for (const productGroupProduct of product.products) {
                    productIdToProduct.set(productGroupProduct.id, productGroupProduct);
                    productIdToCategoryId.set(productGroupProduct.id, category.id);
                }
            } else {
                productIdToProduct.set(product.id, product);
                productIdToCategoryId.set(product.id, category.id);
            }
        }

        if (category.modifiers) {
            for (const modifier of category.modifiers) {
                if (modifier.products && modifier.products.length > 0) {
                    modifierIdToProductModifierSettings.set(modifier.id, {
                        id: modifier.id,
                        min: modifier.minSelect,
                        max: modifier.maxSelect,
                        products: [],
                        title: modifier.title,
                        bundle: !!modifier.bundle,
                        allowQuantitySelect: !!modifier.allowQuantitySelect,
                        isCollapsed: !!modifier.isCollapsed
                    });
                    modifierIdToProductIds.set(
                        modifier.id,
                        modifier.products.map(modProd => modProd.id)
                    );
                    modifierIdToProductModifiers.set(modifier.id, modifier.products);
                }
                if (modifier.options && modifier.options.length > 0) {
                    modifierIdToOptionModifierSettings.set(modifier.id, {
                        id: modifier.id,
                        min: modifier.minSelect,
                        max: modifier.maxSelect,
                        options: modifier.options,
                        title: modifier.title,
                        bundle: !!modifier.bundle,
                        allowQuantitySelect: !!modifier.allowQuantitySelect,
                        isCollapsed: !!modifier.isCollapsed,
                        sort: modifier.sort
                    });
                    modifierIdToProductIds.set(
                        modifier.id,
                        modifier.options.map(modProd => modProd.id)
                    );
                }
            }
        }
        modifierIdToCategoryOptionModifierSettings.set(category.id, modifierIdToOptionModifierSettings);
    }

    const extendedMenu: IEnrichedMenuWithModifierMaps = {
        ...menu,
        productIdToProduct,
        modifierIdToProductIds,
        modifierIdToProductModifiers,
        modifierIdToProductModifierSettings,
        productIdToCategoryId,
        modifierIdToCategoryOptionModifierSettings,
        modifierIdToOptionModifierSettings
    };
    return extendedMenu;
}

export function getModifierProductsForProduct(menu: IEnrichedMenuWithModifierMaps, modifiers: string[]) {
    const productModifiers: IProductModifierSettings[] = [];
    for (const modifier of modifiers) {
        const isModifierProductsType = !!menu.modifierIdToProductModifierSettings.get(modifier);
        const productModifierSettings = menu.modifierIdToProductModifiers.get(modifier);
        if (!productModifierSettings) {
            continue;
        }
        if (!isModifierProductsType) {
            continue;
        }
        const modifierProducts: IEnrichedProduct[] = [];
        const modifierProductIds = menu.modifierIdToProductIds.get(modifier);
        if (!modifierProductIds) {
            continue;
        }
        for (const modifierProductId of modifierProductIds) {
            const modifierProduct = menu.productIdToProduct.get(modifierProductId);

            const productModifier = productModifierSettings.find(product => product.id === modifierProductId);

            if (!modifierProduct) {
                continue;
            }
            modifierProducts.push({
                ...modifierProduct,
                ...(!!productModifier?.imageUrl && {
                    modifierImage: productModifier.imageUrl
                }),
                ...(!!productModifier?.sort && {
                    sort: productModifier.sort
                }),
                ...(!!productModifier?.title && {
                    title: productModifier.title
                }),
                selected: !!productModifier?.selected
            });
        }
        if (modifierProducts.length === 0) {
            continue;
        }
        const modifierSettings = menu.modifierIdToProductModifierSettings.get(modifier);
        if (!modifierSettings) {
            continue;
        }
        productModifiers.push({
            ...modifierSettings,
            products: modifierProducts,
            id: modifier
        });
    }
    return productModifiers;
}

export function getModifierOptionsForProduct(
    menu: IEnrichedMenuWithModifierMaps,
    id: string,
    modifiers?: string[]
) {
    const optionModifiers: IOptionModifierSettings[] = [];
    if (!Array.isArray(modifiers)) {
        return optionModifiers;
    }
    for (const modifier of modifiers) {
        const categoryId = menu.productIdToCategoryId.get(id);
        if (!isDefined(categoryId)) {
            continue;
        }
        const modifierSettings =
            menu.modifierIdToCategoryOptionModifierSettings.get(categoryId)?.get(modifier) ??
            menu.modifierIdToOptionModifierSettings?.get(modifier);
        if (!modifierSettings) {
            continue;
        }
        const modifierOptions = modifierSettings.options;
        if (!modifierOptions?.length) {
            continue;
        }
        optionModifiers.push({
            ...modifierSettings,
            options: modifierOptions,
            id: modifier
        });
    }
    return optionModifiers.slice().sort((a, b) => a.sort - b.sort);
}

export function getModifierProductDetails(
    modifierItems: IItemReadResourceV10[],
    quantity = 1
): [string, number] {
    let price = 0;
    const productModifiersString = modifierItems
        .map(modifierProduct => {
            price += (modifierProduct.cost * modifierProduct.quantity) / quantity;
            const calculatedQuantity = modifierProduct.quantity / quantity;
            return calculatedQuantity > 1
                ? `${calculatedQuantity}x ${modifierProduct.productName}`
                : modifierProduct.productName ?? '';
        })
        .join(', ');
    return [productModifiersString, price];
}

export interface ProductModifierPayItem {
    title: string;
    quantity: number;
    price?: number;
}

export function getPayModifierProductDetails(
    modifierItems: IItemReadResourceV10[],
    quantity = 1
): [ProductModifierPayItem[], number] {
    let price = 0;
    const productModifiers = modifierItems.map(modifierProduct => {
        const modifierPrice = (modifierProduct.cost * modifierProduct.quantity) / quantity;
        price += modifierPrice;
        const calculatedQuantity = modifierProduct.quantity / quantity;
        return { title: modifierProduct.productName, quantity: calculatedQuantity, price: modifierPrice };
    });
    return [productModifiers, price];
}

export function getPayProductOptionsDetails(
    modifiers?: IItemModifierResourceV10[]
): [ProductModifierPayItem[], number] {
    const result: ProductModifierPayItem[] = [];
    let resultPrice = 0;
    if (modifiers) {
        const productsCounter: Record<string, number> = {};
        const productsPrice: Record<string, number> = {};
        for (const modifier of modifiers) {
            if (modifier.options && modifier.options.length) {
                modifier.options.forEach(option => {
                    resultPrice += (option.price || option.cost) ?? 0;
                    if (option.name) {
                        productsCounter[option.name] = (productsCounter[option.name] || 0) + 1;
                        productsPrice[option.name] =
                            (productsPrice[option.name] || 0) + ((option.price || option.cost) ?? 0);
                    } else if (option.title) {
                        productsCounter[option.title] = (productsCounter[option.title] || 0) + 1;
                        productsPrice[option.title] =
                            (productsPrice[option.title] || 0) + ((option.price || option.cost) ?? 0);
                    }
                });
            }
        }
        Object.entries(productsCounter).forEach(([name, productQuantity]) => {
            result.push({
                title: name,
                quantity: productQuantity,
                price: productsPrice[name] || 0
            });
        });
    }
    return [result, resultPrice];
}

export function getProductOptionsDetails(
    modifiers?: IItemModifierResourceV10[],
    includeModifierName = true
): [string, number] {
    let resultString = '';
    let resultPrice = 0;
    if (modifiers) {
        for (const modifier of modifiers) {
            if (modifier.options && modifier.options.length) {
                if (includeModifierName) {
                    if (!!modifier.name) {
                        resultString += ` ${modifier.name} - `;
                    } else if (!!modifier.title) {
                        resultString += ` ${modifier.title} - `;
                    }
                }

                const productsCounter: Record<string, number> = {};
                modifier.options.forEach(option => {
                    resultPrice += option.cost ?? 0;
                    if (option.name) {
                        productsCounter[option.name] = (productsCounter[option.name] || 0) + 1;
                    } else if (option.title) {
                        productsCounter[option.title] = (productsCounter[option.title] || 0) + 1;
                    }
                });
                const modifierOptionString = Object.entries(productsCounter)
                    .map(([name, productQuantity]) =>
                        productQuantity > 1 ? `${productQuantity}x ${name}` : name ?? ''
                    )
                    .join(', ');
                resultString += `${modifierOptionString}, `;
            }
        }
    }
    if (resultString.length !== 0) {
        resultString = resultString.slice(0, resultString.length - 2);
    }
    return [resultString, resultPrice];
}

export const canCategoryBeShown = (category: IEnrichedCategory) =>
    category.visible && !!category.products.length;

export const filterCategoryGroups = (
    categoryGroups: ICategoryGroup[],
    categories: IEnrichedCategory[],
    defaultDisplayCode = ECategoryGroupDisplayCode.SMALL
) =>
    categoryGroups.filter(
        categoryGroup =>
            (categoryGroup.displayCode ?? defaultDisplayCode) !== ECategoryGroupDisplayCode.HIDE &&
            categoryGroup.categoryIds?.length > 0 &&
            categories.filter(
                category => categoryGroup.categoryIds.includes(category.id) && canCategoryBeShown(category)
            ).length > 0
    );

export function getProductData(item: IEnrichedProduct | ProductGroup, quantity: number) {
    const { title } = item;
    const quantityTitle = `${quantity ? `${quantity}x ` : ''}${title}`;

    if (isProductGroup(item)) {
        const availableDefaultProduct = item.products.find(
            product => product.productGroupDefault && isProductAvailable(product)
        );
        const availableSelectedProduct =
            availableDefaultProduct ||
            item.products.reduce<IEnrichedProduct | undefined>(
                (acc, product) =>
                    isProductAvailable(product) && product.price < (acc?.price || 9999999999) ? product : acc,
                undefined
            );
        const defaultProduct = availableSelectedProduct
            ? availableSelectedProduct
            : item.products.find(product => product.productGroupDefault) ||
              item.products.reduce((acc, product) =>
                  product.price < (acc?.price || 9999999999) ? product : acc
              );
        const description = defaultProduct?.shortDescription;
        const image = defaultProduct?.imageUrl;
        const listingImage = defaultProduct?.listingImageUrl;
        const price = defaultProduct?.price;
        const available = !!availableSelectedProduct;
        const modifiers = defaultProduct?.modifiers;
        const tags = defaultProduct?.tags;
        const listingBackgroundColour = defaultProduct?.listingBackgroundColour;
        const listingBackgroundImageUrl = defaultProduct?.listingBackgroundImageUrl;
        const listingTextColour = defaultProduct?.listingTextColour;
        return {
            title: quantityTitle,
            description,
            nutrition: defaultProduct.nutrition,
            image,
            price,
            listingImage,
            available,
            modifiers,
            listingBackgroundColour,
            listingBackgroundImageUrl,
            listingTextColour,
            tags,
            noDefault: !availableDefaultProduct
        };
    }
    return {
        title: quantityTitle,
        description: item.shortDescription,
        image: item.imageUrl,
        price: item.price,
        listingImage: item.listingImageUrl,
        available: isProductAvailable(item),
        modifiers: item.modifiers,
        nutrition: item.nutrition,
        listingBackgroundColour: item.listingBackgroundColour,
        listingBackgroundImageUrl: item.listingBackgroundImageUrl,
        listingTextColour: item.listingTextColour,
        tags: item.tags
    };
}

export const productTagSorter = (
    tagA: { sort?: number; tagGroupSort?: number },
    tagB: { sort?: number; tagGroupSort?: number }
) => {
    if (
        isDefined(tagA.tagGroupSort) &&
        isDefined(tagB.tagGroupSort) &&
        tagA.tagGroupSort !== tagB.tagGroupSort
    ) {
        return tagA.tagGroupSort - tagB.tagGroupSort;
    }

    if (isDefined(tagA.sort) && isDefined(tagB.sort)) {
        return tagA.sort - tagB.sort;
    }

    return 0;
};

export const mapProductTags = (product: IProduct, options: IOptions) => {
    if (product.tags.length > 0) {
        const array = product.tags.reduce((acc, tagId) => {
            const [tagKey, tagValueKey] = tagId.split('/');
            if (
                !isDefined(tagKey) ||
                !isDefined(tagValueKey) ||
                isEmptyString(tagKey) ||
                isEmptyString(tagValueKey)
            ) {
                return acc;
            }
            if (options.tags) {
                const tags = options.tags.find(tag => tag.id === tagKey);
                if (tags) {
                    const currentTag = tags.values.find(value => value.id === tagValueKey);

                    if (!!currentTag && !!currentTag.title) {
                        acc.push({ ...currentTag, tagGroupSort: tags.sort });
                    }
                }
            }
            return acc;
        }, []);

        return {
            ...product,
            tags: array.sort(productTagSorter)
        };
    }
    return product;
};

export const mapProduct = (product: IProduct, menu: IEnrichedMenu) => {
    const updatedProduct = mapProductTags(product, menu.options);

    return updatedProduct;
};
export const mapCategories = (categories: IEnrichedCategory[], menu: IEnrichedMenu) =>
    categories
        .filter(canCategoryBeShown)
        .sort((a, b) => a.sort - b.sort)
        .map(item => ({
            ...item,
            products: item.products
                .reduce<(IEnrichedProduct | ProductGroup)[]>((acc, singleProduct) => {
                    if (item.hideUnavailableProducts && !isProductAvailable(singleProduct)) {
                        return acc;
                    }
                    const product = mapProduct(singleProduct, menu);
                    if (product.productGroupId && product.productGroupName) {
                        const currentGroupIndex = acc.findIndex(group => group.id === product.productGroupId);
                        if (currentGroupIndex < 0) {
                            acc.push({
                                products: [product],
                                title: product.productGroupName,
                                id: product.productGroupId,
                                sort: product.sort
                            });
                        } else {
                            const currentGroup = acc[currentGroupIndex];
                            if (isProductGroup(currentGroup)) {
                                currentGroup.products.push(product);
                            }
                        }
                    } else {
                        acc.push(product);
                    }
                    return acc;
                }, [])
                .sort((a, b) => a.sort - b.sort)
        }));
