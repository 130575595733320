import React from 'react';
import { Helmet } from 'react-helmet';
import * as googleHelpers from 'lib/googleHelpers';

const defaultTitle = 'Pepper Web App';

// TODO: set up dynamic title
export const Head: React.FC = () => (
    <Helmet>
        {process.env.NODE_ENV === 'production' && (
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_TRACKING_ID}`} />
        )}
        {process.env.NODE_ENV === 'production' && (
            <script type="text/javascript">{googleHelpers.getGoogleTagsManagerScript()}</script>
        )}
        {process.env.NODE_ENV === 'production' && (
            <script type="text/javascript">{googleHelpers.getGoogleAnalyticsScript()}</script>
        )}
        {process.env.NODE_ENV === 'production' && (
            <style type="text/javascript">{googleHelpers.getAntiFlickerStyles()}</style>
        )}
        {process.env.NODE_ENV === 'production' && (
            <script type="text/javascript">{googleHelpers.getAntiFlickerScript()}</script>
        )}
        <title>{defaultTitle}</title>
        <meta name="title" content={defaultTitle} />
        <meta charSet="utf-8" />
    </Helmet>
);
