import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@material-ui/core';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { getOrderNotes, OrderNote } from 'components/location/model/Location';
import { OrderScenario } from 'components/order/model/Order';
import { BottomDialog } from 'lib/BottomDialog';
import { setBasketNote } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';
import { ViewBillContentSection } from '../ViewBillContentSection';
import { ViewBillCheckoutNoteDialog } from './ViewBillCheckoutNoteDialog';
import { ViewBillCheckoutNoteItem } from './ViewBillCheckoutNoteItem';

export const ViewBillCheckoutNoteList: React.FC = () => {
    const { notes: basketNotes } = useSelector((state: ApplicationState) => state.basket);
    const [scenario] = useRouteScenario();
    const { currentLocation } = useSelector((state: ApplicationState) => state.locations);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const notes = React.useMemo(() => getOrderNotes(currentLocation, scenario), [currentLocation, scenario]);
    const showNotes = React.useMemo(
        () =>
            Array.isArray(notes) &&
            notes.length &&
            (scenario === OrderScenario.PREORDER ||
                scenario === OrderScenario.ORDER_TO_TABLE ||
                scenario === OrderScenario.TABLE),
        [notes, scenario]
    );
    const [openIndex, setOpenIndex] = React.useState(-1);
    const handleCancel = React.useCallback(() => {
        setOpenIndex(-1);
    }, []);
    const handleItemClick = React.useCallback((value: number) => {
        setOpenIndex(value);
    }, []);
    const selectedNote = React.useMemo(() => {
        if (openIndex < 0) {
            return null;
        }
        const note = notes[openIndex];
        if (!note) {
            return null;
        }
        return { ...note, value: basketNotes[note.title] };
    }, [basketNotes, notes, openIndex]);
    const handleChange = React.useCallback(
        (title: string, value: string) => {
            setBasketNote(title, value)(dispatch);
            setOpenIndex(-1);
        },
        [dispatch]
    );
    const renderItem = React.useCallback(
        (note: OrderNote, index: number) => (
            <ViewBillCheckoutNoteItem
                key={`order-note-${note.title}`}
                title={note.title}
                index={index}
                onClick={handleItemClick}
                value={basketNotes[note.title]}
            />
        ),
        [basketNotes, handleItemClick]
    );
    if (!showNotes) {
        return null;
    }
    return (
        <ViewBillContentSection title={t('BILL_CHECKOUT_ORDER_NOTES')} bottomSpacing={2}>
            <List disablePadding>{notes.map(renderItem)}</List>
            <BottomDialog open={!!selectedNote} onClose={handleCancel}>
                {!!selectedNote && (
                    <ViewBillCheckoutNoteDialog
                        title={selectedNote.title}
                        description={selectedNote.description}
                        onChange={handleChange}
                        initialValue={selectedNote.value}
                    />
                )}
            </BottomDialog>
        </ViewBillContentSection>
    );
};
