import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import shadows from '@material-ui/core/styles/shadows';
import { Layout } from 'components/layout/model/Layout';
import { CONTENT_MAX_WIDTH } from 'config/constants';
import { addOpacity } from 'lib/helpers';

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        titleTextColour: string;
    }

    interface PaletteOptions {
        titleTextColour?: string;
    }
}

const typography: TypographyOptions = {
    fontSize: 14,
    htmlFontSize: 16,
    fontFamily:
        'SecondaryFont, "Helvetica Neue", HelveticaNeue, Roboto, "Liberation Sans", "Nimbus Sans N", Helvetica, Arial, sans-serif',
    body1: {
        fontWeight: 'normal',
        whiteSpace: 'pre-wrap'
    },
    h6: {
        fontWeight: 'normal',
        fontSize: 18
    }
};

// TODO: Should paper be same colour or change it to white?
export const createTheme = (merchantId: string, layout?: Layout) => {
    if (layout) {
        const titleTextColour = layout?.titleTextColour ? layout?.titleTextColour : layout.anchorTextColour;
        const bodyTextColour = layout?.bodyTextColour ? layout?.bodyTextColour : layout.anchorTextColour;
        const dividerColour = addOpacity(bodyTextColour, 0.12);

        shadows[1] = `0px 2px 1px -1px ${addOpacity(
            layout.primaryActionBackgroundColour,
            0.2
        )}, 0px 1px 1px 0px ${addOpacity(
            layout.primaryActionBackgroundColour,
            0.14
        )}, 0px 1px 3px 0px ${dividerColour}`;
        shadows[2] = `0px 3px 1px -2px ${addOpacity(
            layout.primaryActionBackgroundColour,
            0.2
        )}, 0px 2px 2px 0px ${addOpacity(
            layout.primaryActionBackgroundColour,
            0.14
        )}, 0px 1px 5px 0px ${dividerColour}`;
        const tenantFont = {
            fontFamily: 'TenantFont',
            src: `url(${process.env.MEDIA_URL}/tenants/${merchantId}/app_assets/customfont)`
        };
        const secondaryFont = {
            fontFamily: 'SecondaryFont',
            src: `url(${process.env.MEDIA_URL}/tenants/${merchantId}/app_assets/secondary_custom_font)`
        };
        return createMuiTheme({
            typography,
            palette: {
                text: {
                    primary: bodyTextColour,
                    hint: layout.tintColour
                },
                primary: {
                    main: layout.primaryActionBackgroundColour,
                    contrastText: layout.primaryActionTextColour
                },
                secondary: {
                    main: bodyTextColour
                },
                background: {
                    default: layout.anchorBackgroundColour,
                    paper: layout.anchorBackgroundColour
                },
                divider: dividerColour,
                titleTextColour
            },
            shadows,
            shape: {
                borderRadius: layout.useRoundedCorners ? 8 : 0
            },
            overrides: {
                MuiCssBaseline: {
                    '@global': {
                        '.rccs': {
                            width: '100%'
                        },
                        '.rccs__card': {
                            width: 'calc(100vw - 16px)',
                            maxWidth: '450px',
                            height: 'calc(100vw / 1.5858 - 16px / 1.5858)',
                            maxHeight: '283px'
                        },
                        '.rccs__card:not(.rccs__card--unknown) .rccs__card__background': {
                            background: layout.primaryActionBackgroundColour
                        },
                        '.rccs__card--unknown > div': {
                            background: layout.primaryActionBackgroundColour
                        },
                        '.rccs__card--front': {
                            color: layout.primaryActionTextColour
                        },
                        '@font-face': [tenantFont, secondaryFont]
                    }
                },
                MuiIconButton: {
                    root: {
                        color: addOpacity(bodyTextColour, 0.54)
                    }
                },
                MuiFormLabel: {
                    root: {
                        color: addOpacity(bodyTextColour, 0.54),
                        '&$focused': {
                            color: bodyTextColour
                        }
                    }
                },
                MuiButton: {
                    contained: {
                        '&$disabled': {
                            color: addOpacity(bodyTextColour, 0.2),
                            backgroundColor: addOpacity(bodyTextColour, 0.3),
                            lineHeight: '20px'
                        }
                    }
                },
                MuiCheckbox: {
                    root: {
                        color: layout.tintColour
                    }
                },
                MuiRadio: {
                    root: {
                        color: layout.tintColour
                    },
                    colorPrimary: {
                        color: bodyTextColour
                    }
                },
                MuiInput: {
                    underline: {
                        '&:before': {
                            borderBottomColor: bodyTextColour
                        }
                    }
                },
                MuiInputBase: {
                    root: {
                        '&$disabled': {
                            color: `${addOpacity(bodyTextColour, 0.38)}`
                        }
                    }
                },
                MuiInputLabel: {
                    outlined: {
                        '&$disabled': {
                            color: `${addOpacity(bodyTextColour, 0.38)}`
                        }
                    }
                },
                MuiOutlinedInput: {
                    notchedOutline: {
                        borderColor: addOpacity(bodyTextColour, 0.23)
                    },

                    root: {
                        '&$disabled': {
                            '& $notchedOutline': {
                                borderColor: `${addOpacity(bodyTextColour, 0.38)}`
                            }
                        }
                    }
                },
                MuiDialog: {
                    paperFullScreen: {
                        maxWidth: `${CONTENT_MAX_WIDTH}px`
                    }
                },
                MuiDialogTitle: {
                    root: {
                        color: titleTextColour
                    }
                }
            }
        });
    }
    return createMuiTheme({
        typography
    });
};
