import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { Image } from 'lib/Image';

interface IProps {
    backgroundImg: string;
    defaultImage: string;
    onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        journeySelectionWrapper: {
            paddingTop: `${(150 / 388) * 100}%`,
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            position: 'relative'
        },
        journeyImage: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        },
        journeyImageHidden: {
            width: 0,
            height: 0
        }
    })
);

export const ScenarioCard: React.FC<IProps> = ({ backgroundImg, onClick, defaultImage }) => {
    const [laoding, setLoading] = React.useState(true);
    const classes = useStyles();

    const onLoad = React.useCallback(() => {
        setLoading(false);
    }, []);

    return (
        <div className={classes.journeySelectionWrapper} onClick={onClick}>
            <Skeleton
                className={classes.journeyImage}
                variant="rect"
                animation="wave"
                style={{ zIndex: 100, display: laoding ? 'block' : 'none' }}
            />
            <Image
                src={backgroundImg}
                onError={onLoad}
                onLoad={onLoad}
                fallbackSrc={defaultImage}
                className={clsx(classes.journeyImage, { [classes.journeyImageHidden]: laoding })}
            />
        </div>
    );
};
