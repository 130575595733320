import * as React from 'react';
import { Box, createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import color from 'color';
import { EAwardsScreenShape, IAwardVisualization, IPointAward } from 'components/awards/models';
import { ELoyaltyTemplate } from 'components/awards/enums';
import clsx from 'clsx';

interface IProps {
    visualization: IAwardVisualization;
    ignoreAlignment: boolean;
    onClick: (award: IPointAward) => void;
    award: IPointAward;
}

const getFontSizeByShape = (awardsScreenShape?: EAwardsScreenShape) => {
    switch (awardsScreenShape) {
        case EAwardsScreenShape.LARGE_SQUARE:
            return 14;
        case EAwardsScreenShape.SMALL_SQUARE:
            return 8;
        default:
            return undefined;
    }
};

const getGridItemSizeByShape = (awardsScreenShape?: EAwardsScreenShape): 'small' | 'large' | 'rectangle' => {
    switch (awardsScreenShape) {
        case EAwardsScreenShape.LARGE_SQUARE:
            return 'large';
        case EAwardsScreenShape.SMALL_SQUARE:
            return 'small';
        default:
            return 'rectangle';
    }
};

const getOverlayPaddingByShape = (awardsScreenShape?: EAwardsScreenShape) => {
    switch (awardsScreenShape) {
        case EAwardsScreenShape.LARGE_SQUARE:
            return 1.5;
        case EAwardsScreenShape.SMALL_SQUARE:
            return 1;
        case EAwardsScreenShape.SINGLE_RECTANGLE:
        case EAwardsScreenShape.DOUBLE_RECTANGLE:
        default:
            return 2;
    }
};

const getContainerPaddingByShape = (awardsScreenShape?: EAwardsScreenShape) => {
    switch (awardsScreenShape) {
        case EAwardsScreenShape.LARGE_SQUARE:
        case EAwardsScreenShape.SMALL_SQUARE:
            return '100%';
        case EAwardsScreenShape.SINGLE_RECTANGLE:
            return `${100 / 3}%`;
        case EAwardsScreenShape.DOUBLE_RECTANGLE:
        default:
            return `${(100 / 3) * 2}%`;
    }
};

const useStyles = ({
    backgroundImageUrl,
    backgroundOverlayColour,
    backgroundOverlayOpacity,
    textColour,
    awardsScreenShape
}: IAwardVisualization) => {
    const isValidBackground = /^#([0-9A-F]{3}){1,2}$/i.test(backgroundOverlayColour || '');
    const padding = getContainerPaddingByShape(awardsScreenShape);

    return makeStyles((theme: Theme) =>
        createStyles({
            paper: {
                width: '100%',
                padding: theme.spacing(0, 0.5)
            },
            container: {
                position: 'relative',
                borderRadius: theme.shape.borderRadius,
                backgroundImage: `url(${backgroundImageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                overflow: 'hidden',
                color: textColour,
                width: '100%',
                paddingBottom: padding
            },
            overlay: {
                position: 'absolute',
                backgroundColor: isValidBackground
                    ? color(backgroundOverlayColour)
                          .alpha(backgroundOverlayOpacity || 0)
                          .rgb()
                          .string()
                    : 'initial',
                width: '100%',
                height: '100%',
                fontSize: getFontSizeByShape(awardsScreenShape),
                padding: theme.spacing(getOverlayPaddingByShape(awardsScreenShape))
            },
            small: {
                maxWidth: '33.333333%',
                flexGrow: 0,
                flexBasis: '33.333333%'
            },
            large: {
                maxWidth: '50%',
                flexGrow: 0,
                flexBasis: '50%'
            },
            rectangle: {
                maxWidth: '100%',
                flexGrow: 0,
                flexBasis: '100%'
            }
        })
    );
};

export const AwardVisualizer: React.FC<React.PropsWithChildren<IProps>> = React.memo(
    ({ children, visualization, ignoreAlignment, onClick, award }) => {
        const classes = useStyles({
            ...visualization,
            awardsScreenShape:
                award.template === ELoyaltyTemplate.AD_HOC || award.template === ELoyaltyTemplate.CLUB
                    ? visualization.awardsScreenShape
                    : undefined
        })();
        const alignItems = React.useMemo(() => {
            if (!ignoreAlignment) {
                switch (visualization.verticalAlignment) {
                    case 'CENTER':
                        return 'center';
                    case 'BOTTOM':
                        return 'flex-end';
                    default:
                        return 'flex-start';
                }
            }
            return 'flex-start';
        }, [ignoreAlignment, visualization.verticalAlignment]);
        const handleClick = React.useCallback(() => onClick(award), [award, onClick]);

        const shapeClassName = React.useMemo(
            () =>
                getGridItemSizeByShape(
                    award?.template === ELoyaltyTemplate.AD_HOC || award?.template === ELoyaltyTemplate.CLUB
                        ? visualization.awardsScreenShape
                        : undefined
                ),
            [award?.template, visualization.awardsScreenShape]
        );

        return (
            <Paper
                elevation={0}
                onClick={handleClick}
                className={clsx(classes.paper, classes[shapeClassName])}
            >
                <div className={classes.container}>
                    <Box
                        display="flex"
                        alignItems={alignItems}
                        textAlign={visualization.textAlignment?.toLowerCase()}
                        className={classes.overlay}
                    >
                        {children}
                    </Box>
                </div>
            </Paper>
        );
    },
    (prevProps, nextProps) => prevProps.onClick === nextProps.onClick
);

AwardVisualizer.displayName = 'AwardVisualizer';
