import React from 'react';
import { Box, createStyles, Divider, ListItem, ListItemText, makeStyles, Theme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { useCalories } from 'src/hooks/useCalories';
import { addOpacity, showCalories } from 'lib/helpers';
import { isDefined, isNumber } from 'lib/typeInference';
import { useCurrencyString } from 'lib/useCurrencyString';
import {
    getModifierOptionsForProduct,
    getModifierProductsForProduct,
    getProductData,
    IEnrichedMenuWithModifierMaps,
    IEnrichedProduct,
    IProductTagValue,
    ProductGroup
} from './model/Menu';
import { useTranslation } from 'react-i18next';

interface MenuProductProps {
    item: IEnrichedProduct | ProductGroup;
    quantity: number;
    onClick?: (product: IEnrichedProduct | ProductGroup) => void;
    menu?: IEnrichedMenuWithModifierMaps;
    isDesktop?: boolean;
}

interface ProductStyles {
    listingBackgroundColour?: string;
    listingBackgroundImageUrl?: string;
    listingTextColour?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: ({
            listingBackgroundColour,
            listingBackgroundImageUrl,
            listingTextColour
        }: ProductStyles) => {
            const isBackgroundImage = isDefined(listingBackgroundImageUrl);
            const defaultStyles = {
                padding: theme.spacing(1, 1.5),
                color: listingTextColour ?? theme.palette.text.primary
            };
            if (isBackgroundImage) {
                return {
                    ...defaultStyles,
                    position: 'relative',
                    '&:before': {
                        content: "' '",
                        display: 'block',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 1,
                        backgroundImage: `url(${listingBackgroundImageUrl})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '50% 0',
                        backgroundSize: '100% 100%'
                    }
                };
            }
            if (isDefined(listingBackgroundColour)) {
                return {
                    ...defaultStyles,
                    background: listingBackgroundColour,
                    '&:hover': {
                        background: addOpacity(listingBackgroundColour, 0.95)
                    }
                };
            }
            return defaultStyles;
        },
        box: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 1,
            zIndex: 1
        },
        description: ({ listingTextColour }) => ({
            '& .MuiListItemText-secondary': {
                color: listingTextColour ?? theme.palette.text.primary,
                opacity: 0.6,
                overflow: 'hidden',
                position: 'relative',
                maxHeight: '3em',
                paddingRight: '1em'
            }
        }),
        imageWrapper: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            marginLeft: theme.spacing(2),
            position: 'relative',
            flexShrink: 0,
            overflow: 'hidden',
            borderRadius: theme.shape.borderRadius
        },
        content: {
            display: 'flex',
            alignItems: 'space-between',
            height: '100%',
            flexDirection: 'column'
        },
        fontWeightBold: {
            fontWeight: theme.typography.fontWeightBold
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        },
        imageLoader: {
            borderRadius: theme.shape.borderRadius,
            marginLeft: theme.spacing(2),
            flexShrink: 0
        },
        selectedItem: ({ listingTextColour }) => ({
            borderLeft: `${theme.spacing(0.75)}px solid ${listingTextColour ?? theme.palette.text.primary}`,
            paddingLeft: theme.spacing(1)
        }),
        imageHidden: {
            visibility: 'hidden',
            width: 0,
            height: 0,
            padding: 0,
            margin: 0
        },
        tagImageContainer: {
            height: 22,
            width: 22
        },
        tagImage: {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        },
        itemDescription: {
            flex: 'unset'
        }
    })
);

export const MenuProduct: React.FC<MenuProductProps> = ({ item, onClick, quantity, menu, isDesktop }) => {
    const { t } = useTranslation();
    const { getCaloriesString, getProductCalories } = useCalories();
    const getCurrencyString = useCurrencyString();
    const [imageLoaded, setImageLoaded] = React.useState(false);
    const handleClick = React.useCallback(() => {
        if (onClick) {
            onClick(item);
        }
    }, [onClick, item]);
    const {
        description,
        title,
        image,
        listingImage,
        price,
        available,
        modifiers,
        tags,
        listingBackgroundColour,
        listingBackgroundImageUrl,
        listingTextColour,
        noDefault
    } = React.useMemo(() => getProductData(item, quantity), [item, quantity]);
    const classes = useStyles(
        isDesktop ? {} : { listingBackgroundColour, listingBackgroundImageUrl, listingTextColour }
    );
    const productModifiers = React.useMemo(
        () => (menu ? getModifierProductsForProduct(menu, modifiers || []) : []),
        [menu, modifiers]
    );
    const optionModifiers = React.useMemo(
        () => (menu ? getModifierOptionsForProduct(menu, item.id, modifiers) : []),
        [item.id, menu, modifiers]
    );
    const priceWithModifiers = React.useMemo(() => {
        const productModifiersPrice = productModifiers.reduce((acc, modifier) => {
            if (modifier.products.length > 0) {
                const selectedModifiers = modifier.products.filter(
                    modifierProduct => modifierProduct.selected
                );
                if (selectedModifiers.length) {
                    const localPrice = selectedModifiers
                        .slice(0, modifier.max)
                        .reduce((modifierPrice, modifierProduct) => modifierPrice + modifierProduct.price, 0);
                    return acc + localPrice;
                }
                if (modifier.bundle && isNumber(modifier.min) && modifier.min > 0) {
                    const minPriceProduct = modifier.products.reduce((minPrice, modifierProduct) => {
                        if (minPrice <= modifierProduct.price) {
                            return minPrice;
                        }
                        return modifierProduct.price;
                    }, modifier.products[0].price);
                    return acc + minPriceProduct * modifier.min;
                }
            }
            return acc;
        }, 0);
        const optionModifiersPrice = optionModifiers.reduce((acc, modifier) => {
            if (modifier.options.length > 0) {
                const selectedModifiers = modifier.options.filter(modifierOption => modifierOption.selected);
                if (selectedModifiers.length) {
                    const localPrice = selectedModifiers
                        .slice(0, modifier.max)
                        .reduce((modifierPrice, modifierOption) => modifierPrice + modifierOption.price, 0);
                    return acc + localPrice;
                }
                if (modifier.bundle && isNumber(modifier.min) && modifier.min > 0) {
                    const minPriceOption = modifier.options.reduce((minPrice, modifierOption) => {
                        if (minPrice <= modifierOption.price) {
                            return minPrice;
                        }
                        return modifierOption.price;
                    }, modifier.options[0].price);
                    return acc + minPriceOption * modifier.min;
                }
            }
            return acc;
        }, 0);
        return (price || 0) + productModifiersPrice + optionModifiersPrice;
    }, [productModifiers, optionModifiers, price]);
    const handleImageLoaded = React.useCallback(() => {
        setImageLoaded(true);
    }, []);
    const calories = React.useMemo(() => getProductCalories(item), [getProductCalories, item]);
    const itemDescription = React.useMemo(() => {
        if (noDefault) {
            return `${t('PREORDER_VARIOUS_PRICE')} ${
                showCalories(calories) ? ` | ${getCaloriesString(calories.value)}` : ''
            }`;
        }
        if (priceWithModifiers) {
            return `${getCurrencyString(priceWithModifiers)} ${
                showCalories(calories) ? ` | ${getCaloriesString(calories.value)}` : ''
            }`;
        }

        return calories.isSet ? getCaloriesString(calories.value) : '';
    }, [calories, getCaloriesString, getCurrencyString, noDefault, priceWithModifiers, t]);

    const renderTag = React.useCallback(
        (tag: IProductTagValue) =>
            !!tag.imageUrl && (
                <Box marginLeft={0.75} className={classes.tagImageContainer} key={tag.title}>
                    <img className={classes.tagImage} src={tag.imageUrl} alt="" />
                </Box>
            ),
        [classes.tagImage, classes.tagImageContainer]
    );

    return (
        <div>
            <Box paddingX={1.5}>
                <Divider />
            </Box>
            <ListItem
                button
                disableGutters
                disabled={!available}
                onClick={handleClick}
                className={clsx(classes.listItem, { [classes.selectedItem]: quantity !== 0 })}
            >
                <div className={classes.box}>
                    <div className={classes.content}>
                        <ListItemText
                            primaryTypographyProps={{
                                className: classes.fontWeightBold,
                                variant: 'h6'
                            }}
                            primary={title}
                        />
                        {!!description && (
                            <ListItemText secondary={description} className={classes.description} />
                        )}
                        <Box display="flex" alignItems="center">
                            {itemDescription.length > 0 && (
                                <ListItemText
                                    className={classes.itemDescription}
                                    secondary={itemDescription}
                                    secondaryTypographyProps={{
                                        color: 'inherit'
                                    }}
                                />
                            )}

                            {!!tags && !!tags.length && (
                                <Box marginLeft={0} display="flex">
                                    {tags.map(renderTag)}
                                </Box>
                            )}
                        </Box>
                    </div>
                    {(!!image || !!listingImage) && (
                        <>
                            <Box
                                className={clsx(classes.imageWrapper, {
                                    [classes.imageHidden]: !imageLoaded
                                })}
                            >
                                <img
                                    className={classes.image}
                                    onLoad={handleImageLoaded}
                                    src={listingImage || image}
                                />
                            </Box>
                            {!imageLoaded && <Skeleton className={classes.imageWrapper} variant="rect" />}
                        </>
                    )}
                </div>
            </ListItem>
        </div>
    );
};
