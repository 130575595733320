import * as React from 'react';
import { Box } from '@material-ui/core';
import { ELoyaltyTemplate } from 'components/awards/enums';
import { EAwardsScreenShape, IPointAward } from 'components/awards/models';
import { AdHoc } from './templates/AdHoc';
import { AwardVisualizer } from './templates/AwardVisualizer';
import { PseudoCurrency } from './templates/PseudoCurrency';
import { StampCard } from './templates/StampCard';
import { AwardDetailsDialog } from './AwardDetailsDialog';
import { isAwardAvailableToday } from 'components/awards/utils';
import { AwardUserSection } from '../user-section/AwardUserSection';
import { NoAwardsPlaceholder } from './NoAwardsPlaceholder';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

interface IProps {
    awards: IPointAward[];
}

export const AwardsList: React.FC<IProps> = React.memo(({ awards }) => {
    const [selectedAward, setSelectedAward] = React.useState<IPointAward>();
    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const getAwardByType = React.useCallback((award: IPointAward) => {
        switch (award.template) {
            case ELoyaltyTemplate.AD_HOC:
            case ELoyaltyTemplate.CLUB:
                return <AdHoc award={award} />;
            case ELoyaltyTemplate.STAMP_CARD:
                return <StampCard award={award} />;
            case ELoyaltyTemplate.PSEUDO_CURRENCY:
                return <PseudoCurrency award={award} />;
            default:
                return null;
        }
    }, []);

    const handleAwardClick = React.useCallback(
        (award: IPointAward) => {
            setSelectedAward(award);
        },
        [setSelectedAward]
    );
    const renderAward = React.useCallback(
        (award: IPointAward) => (
            <AwardVisualizer
                key={award._id}
                ignoreAlignment={award.template === ELoyaltyTemplate.STAMP_CARD}
                visualization={award.visualisation}
                award={award}
                onClick={handleAwardClick}
            >
                {getAwardByType(award)}
            </AwardVisualizer>
        ),
        [getAwardByType, handleAwardClick]
    );

    const handleAwardDetailsClose = React.useCallback(() => {
        setSelectedAward(undefined);
    }, []);

    const filteredAwards = React.useMemo(
        () => awards.filter(item => isAwardAvailableToday(item)).sort((a, b) => b.priority - a.priority),
        [awards]
    );

    const awardRows = React.useMemo(() => {
        const rows: IPointAward[][] = [];
        let currentRow: IPointAward[] = [];
        let tempRow: IPointAward[] = [];
        let currentRowType: EAwardsScreenShape | undefined;
        let tempRowType: EAwardsScreenShape | undefined;
        filteredAwards.forEach(award => {
            let rowType: EAwardsScreenShape | undefined;
            // Get the current row type
            if (award.template === ELoyaltyTemplate.CLUB || award.template === ELoyaltyTemplate.AD_HOC) {
                rowType = award.visualisation.awardsScreenShape;
            }
            if (!rowType) {
                rowType = EAwardsScreenShape.DOUBLE_RECTANGLE;
            }
            // If rectangle, we add it as a row
            if (
                rowType === EAwardsScreenShape.DOUBLE_RECTANGLE ||
                rowType === EAwardsScreenShape.SINGLE_RECTANGLE
            ) {
                // Check if we have currentRow stored
                if (currentRow.length > 0) {
                    rows.push(currentRow);
                    currentRow = [];
                    currentRowType = undefined;
                }
                // Check if we have tempRow stored
                if (tempRow.length > 0) {
                    rows.push(tempRow);
                    tempRow = [];
                    tempRowType = undefined;
                }
                rows.push([award]);
            } else {
                // For other shapes it's getting complicated
                // If we have current row stored, we check the type
                // eslint-disable-next-line no-lonely-if
                if (currentRow.length > 0) {
                    if (currentRowType === rowType) {
                        currentRow.push(award);
                        if (currentRowType === EAwardsScreenShape.LARGE_SQUARE || currentRow.length === 3) {
                            rows.push(currentRow);
                            if (tempRow.length > 0) {
                                currentRow = tempRow;
                                currentRowType = tempRowType;
                                tempRow = [];
                                tempRowType = undefined;
                            } else {
                                currentRow = [];
                                currentRowType = undefined;
                            }
                        }
                    } else if (tempRow.length > 0) {
                        rows.push(currentRow);
                        currentRow = tempRow;
                        currentRowType = tempRowType;
                        tempRow = [];
                        tempRowType = undefined;
                        currentRow.push(award);
                        if (currentRowType === EAwardsScreenShape.LARGE_SQUARE || currentRow.length === 3) {
                            rows.push(currentRow);
                            currentRow = [];
                            currentRowType = undefined;
                        }
                    } else {
                        tempRow = [award];
                        tempRowType = rowType;
                    }
                } else {
                    currentRow.push(award);
                    currentRowType = rowType;
                }
            }
        });
        if (currentRow.length > 0) {
            rows.push(currentRow);
        }
        if (tempRow.length > 0) {
            rows.push(tempRow);
        }
        return rows;
    }, [filteredAwards]);

    const renderRow = React.useCallback(
        (row: IPointAward[], index: number) => (
            <Box width="100%" key={`row-${index}`} display="flex" flex={1} pt={1}>
                {row.map(renderAward)}
            </Box>
        ),
        [renderAward]
    );

    const showBarcode = React.useMemo(
        () =>
            settings?.offlineCheckinEnabled &&
            (!settings?.app?.awardsScreenBarcodePosition ||
                settings?.app?.awardsScreenBarcodePosition === 'INLINE'),
        [settings?.app?.awardsScreenBarcodePosition, settings?.offlineCheckinEnabled]
    );

    return (
        <>
            <Box padding={1}>
                {showBarcode && (
                    <Box>
                        <AwardUserSection />
                    </Box>
                )}
                {awardRows.length > 0 ? awardRows.map(renderRow) : <NoAwardsPlaceholder />}
            </Box>
            {!!selectedAward && (
                <AwardDetailsDialog award={selectedAward} onClose={handleAwardDetailsClose} />
            )}
        </>
    );
});
