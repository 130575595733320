import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import {
    AppBar,
    Box,
    createStyles,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    SvgIconTypeMap,
    Theme,
    Toolbar,
    Typography
} from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HistoryIcon from '@material-ui/icons/History';
import PaymentIcon from '@material-ui/icons/Payment';
import SignUpIcon from '@material-ui/icons/PersonAdd';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import clsx from 'clsx';
import { resetLocalJourney } from 'components/journey/localStore';
import { getLocalMerchantId } from 'components/settings/localStore';
import { getOnCloseRedirectUrl, setOnCloseRedirectUrl } from 'components/user/localAuth';
import { CONTENT_MAX_WIDTH, NAVBAR_ICON_BUTTON_PADDING } from 'config/constants';
import { isString } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ROUTES } from 'pages/routes';
import { logout, resetReturnUrl, setReturnUrl } from 'store/auth/authActions';
import { resetAwards } from 'store/basket/basketActions';
import { EGAEventName, useGAHelpers } from 'lib/useGAHelpers';
import { ApplicationState } from 'store/store';
import { EPaymentProvider } from 'components/settings/model/Settings';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.main
        },
        title: {
            textAlign: 'center',
            width: `calc(100% - ${theme.spacing(12)}px)`,
            fontFamily: `TenantFont, ${theme.typography.fontFamily}`,
            color: theme.palette.titleTextColour
        },
        emptyButton: {
            width: theme.spacing(6),
            height: 1
        },
        backButton: {
            color: theme.palette.primary.main
        },
        marginBlock: {
            flexShrink: 0,
            width: '100%'
        },
        icon: {
            marginRight: theme.spacing(2)
        },
        userAvatar: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            borderRadius: '50%'
        },
        userInitials: {
            fontSize: theme.spacing(1.5),
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        toolbar: {
            width: '100%',
            margin: '0 auto',
            maxWidth: `${CONTENT_MAX_WIDTH + NAVBAR_ICON_BUTTON_PADDING}px`
        },
        color: {
            color: theme.palette.titleTextColour
        }
    })
);

interface IMuiAppBarProps {
    title: string | React.ReactNode;
    disableBack?: boolean;
    disabledMenu?: boolean;
    onBack?: () => void;
    icon?: OverridableComponent<SvgIconTypeMap>;
    elevation?: number;
    ignoreGA?: boolean;
    onBackEventName?: string;
}

const paymentProvidersWithCardSupport: string[] = [
    EPaymentProvider.JUDOPAY,
    EPaymentProvider.SQUARE,
    EPaymentProvider.STRIPE,
    EPaymentProvider.WORLDPAY
];

export const MuiAppBar: React.FC<IMuiAppBarProps> = ({
    title,
    children,
    disableBack,
    disabledMenu,
    onBack,
    elevation,
    icon,
    ignoreGA,
    onBackEventName
}) => {
    const merchantId = React.useMemo(getLocalMerchantId, []);
    const appBarRef = React.useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(undefined);
    const [isAvatarAvailable, setIsAvatarAvailable] = React.useState<boolean>(true);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { push, history } = useLocalHistory();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { isLoggedIn, user } = useAuth();
    const { logUserEvent } = useGAHelpers();
    const userId = React.useMemo(() => (user ? user._id : ''), [user]);
    const isAuthorizationUrl = React.useMemo(() => {
        const authorizationRoutes = [
            ROUTES.USER.LOGIN,
            ROUTES.USER.REGISTER,
            ROUTES.USER.DETAILS,
            ROUTES.USER.REWARDS,
            ROUTES.USER.ACTIVITY,
            ROUTES.USER.PAYMENT_METHODS
        ];
        return authorizationRoutes.some(item => !!matchPath(pathname, item));
    }, [pathname]);
    const handleLeftClick = React.useCallback(() => {
        if (!ignoreGA) {
            if (icon) {
                logUserEvent(onBackEventName ?? EGAEventName.CustomCloseIconClick);
            } else {
                logUserEvent(onBackEventName ?? EGAEventName.BackIconClick);
            }
        }
        if (typeof onBack === 'function') {
            onBack();
        } else if (isAuthorizationUrl) {
            if (merchantId) {
                const redirectUrl = getOnCloseRedirectUrl(merchantId);
                if (redirectUrl) {
                    const [path, queryparam] = redirectUrl.split('?');
                    push(path, {}, queryparam);
                    setOnCloseRedirectUrl('', merchantId);
                } else {
                    history.goBack();
                }
            }
        } else {
            history.goBack();
        }
    }, [
        ignoreGA,
        onBack,
        isAuthorizationUrl,
        icon,
        logUserEvent,
        onBackEventName,
        merchantId,
        push,
        history
    ]);
    const handleRightClick = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl((value?: HTMLElement) => (!!value ? undefined : e.currentTarget));
    }, []);
    const closeMenu = React.useCallback(() => setAnchorEl(undefined), []);

    const handleSignIn = React.useCallback(() => {
        setReturnUrl(pathname)(dispatch);
        if (merchantId) {
            setOnCloseRedirectUrl(pathname, merchantId);
        }
        push(ROUTES.USER.LOGIN);
    }, [pathname, dispatch, merchantId, push]);
    const handleSignUp = React.useCallback(() => {
        setReturnUrl(pathname)(dispatch);
        if (merchantId) {
            setOnCloseRedirectUrl(pathname, merchantId);
        }
        push(ROUTES.USER.REGISTER);
    }, [pathname, dispatch, merchantId, push]);
    const handleLogOut = React.useCallback(async () => {
        await logout(userId)(dispatch);
        resetAwards(dispatch);
        if (!!matchPath(pathname, ROUTES.USER.DETAILS) && merchantId) {
            resetReturnUrl(dispatch);
            resetLocalJourney(merchantId);
        }
        setAnchorEl(undefined);
    }, [dispatch, merchantId, pathname, userId]);
    const handleProfileClick = React.useCallback(() => {
        if (merchantId && !matchPath(pathname, ROUTES.USER.DETAILS)) {
            setOnCloseRedirectUrl(pathname, merchantId);
        }
        push(ROUTES.USER.DETAILS);
    }, [pathname, merchantId, push]);
    const handleRewardsClick = React.useCallback(() => {
        if (merchantId && !matchPath(pathname, ROUTES.USER.DETAILS)) {
            setOnCloseRedirectUrl(pathname, merchantId);
        }
        push(ROUTES.USER.REWARDS);
    }, [merchantId, pathname, push]);
    const handleActivityClick = React.useCallback(() => {
        setReturnUrl(pathname)(dispatch);
        if (merchantId && !matchPath(pathname, ROUTES.USER.DETAILS)) {
            setOnCloseRedirectUrl(pathname, merchantId);
        }
        push(ROUTES.USER.ACTIVITY);
    }, [dispatch, merchantId, pathname, push]);
    const handlePaymentMethodsClick = React.useCallback(() => {
        setReturnUrl(pathname)(dispatch);
        if (merchantId && !matchPath(pathname, ROUTES.USER.DETAILS)) {
            setOnCloseRedirectUrl(pathname, merchantId);
        }
        push(ROUTES.USER.PAYMENT_METHODS);
    }, [dispatch, merchantId, pathname, push]);
    const Icon = React.useMemo(() => (icon ? icon : ArrowBackIcon), [icon]);
    const menuId = 'app-bar-menu-id';
    const handleError = React.useCallback(() => {
        setIsAvatarAvailable(false);
    }, []);
    const userInitials = React.useMemo(() => {
        if (!user || (!user.firstName && !user.lastName)) {
            return undefined;
        }
        return user.firstName[0] + user.lastName[0];
    }, [user]);
    const showPaymentMethods = React.useMemo(
        () =>
            isLoggedIn &&
            !!settings?.paymentProvider &&
            paymentProvidersWithCardSupport.includes(settings?.paymentProvider) &&
            (settings?.preOrderEnabled || settings?.orderToTableEnabled || settings?.payAtTableEnabled),
        [
            isLoggedIn,
            settings?.orderToTableEnabled,
            settings?.payAtTableEnabled,
            settings?.paymentProvider,
            settings?.preOrderEnabled
        ]
    );
    return (
        <React.Fragment>
            <div className={classes.marginBlock} style={{ height: appBarRef.current?.clientHeight ?? 48 }} />
            <AppBar ref={appBarRef} position="fixed" className={classes.appBar} elevation={elevation || 0}>
                <Toolbar className={classes.toolbar} disableGutters variant="dense">
                    {!disableBack ? (
                        <IconButton onClick={handleLeftClick}>
                            <Icon className={classes.backButton} />
                        </IconButton>
                    ) : (
                        <div className={classes.emptyButton} />
                    )}
                    {isString(title) ? (
                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                    ) : (
                        <Box flex={1} className={classes.color}>
                            {title}
                        </Box>
                    )}
                    {children}
                    {!disabledMenu && (
                        <IconButton
                            aria-label="show more"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleRightClick}
                            color="inherit"
                        >
                            {isLoggedIn &&
                                (isAvatarAvailable ? (
                                    <img
                                        className={classes.userAvatar}
                                        src={`${process.env.MEDIA_URL}/users/avatars/${user?.id}.jpg`}
                                        onError={handleError}
                                    />
                                ) : (
                                    <span className={clsx(classes.userAvatar, classes.userInitials)}>
                                        {userInitials}
                                    </span>
                                ))}
                            {!isLoggedIn && <AccountCircle />}
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            {!!anchorEl && !disabledMenu && (
                <Menu anchorEl={anchorEl} id={menuId} open={!!anchorEl} onClose={closeMenu}>
                    {!isLoggedIn && (
                        <MenuItem button onClick={handleSignIn}>
                            <ExitToAppIcon className={classes.icon} />
                            {t('ONBOARDING_SIGN_IN')}
                        </MenuItem>
                    )}
                    {!isLoggedIn && (
                        <MenuItem button onClick={handleSignUp}>
                            <SignUpIcon className={classes.icon} />
                            {t('ONBOARDING_SIGN_UP')}
                        </MenuItem>
                    )}
                    {isLoggedIn && (
                        <MenuItem button onClick={handleProfileClick}>
                            <AccountCircle className={classes.icon} />
                            {t('MY_DETAILS_TITLE')}
                        </MenuItem>
                    )}
                    {isLoggedIn && !!settings?.loyaltyEnabled && (
                        <MenuItem button onClick={handleRewardsClick}>
                            <StarOutlinedIcon className={classes.icon} />
                            {t('AUTH_REWARDS')}
                        </MenuItem>
                    )}
                    {isLoggedIn && (
                        <MenuItem button onClick={handleActivityClick}>
                            <HistoryIcon className={classes.icon} />
                            {t('MAIN_MENU_ACTIVITY')}
                        </MenuItem>
                    )}
                    {isLoggedIn && showPaymentMethods && (
                        <MenuItem button onClick={handlePaymentMethodsClick}>
                            <PaymentIcon className={classes.icon} />
                            {t('ACCOUNT_PAYMENT')}
                        </MenuItem>
                    )}
                    {isLoggedIn && (
                        <MenuItem button onClick={handleLogOut}>
                            <ExitToAppIcon className={classes.icon} />
                            {t('AUTH_LOGOUT')}
                        </MenuItem>
                    )}
                </Menu>
            )}
        </React.Fragment>
    );
};

MuiAppBar.defaultProps = {
    elevation: 1
};
