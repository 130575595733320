import {
    Box,
    Dialog,
    Slide,
    SlideProps,
    Theme,
    Typography,
    createStyles,
    makeStyles
} from '@material-ui/core';
import { Markdown } from 'lib/Markdown';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import React from 'react';
import Close from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { Throbber } from 'lib/Throbber';
import Axios from 'axios';
import { BaseRouteParams } from 'pages/routes';
import { useParams } from 'react-router-dom';

const transitionProps: SlideProps = { direction: 'up' };

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            textDecoration: 'underline',
            fontWeight: 'bold',
            cursor: 'pointer',
            color: theme.palette.text.hint
        },
        markdownContainer: {
            padding: theme.spacing(1),
            width: '100%',
            height: '100%',
            paddingBottom: theme.spacing(7),
            overflow: 'hidden auto'
        }
    })
);

interface TermsAndConditionsProps {
    variant?: 'caption';
}

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ variant }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { merchantId } = useParams<BaseRouteParams>();
    const [termsOpen, setTermsOpen] = React.useState(false);
    const [privacyOpen, setPrivacyOpen] = React.useState(false);
    const [termsMarkdown, setTermsMarkdown] = React.useState<string | null>(null);
    const [privacyMarkdown, setPrivacyMarkdown] = React.useState<string | null>(null);
    const handleTermsClick = React.useCallback(() => setTermsOpen(true), []);
    const handlePrivacyClick = React.useCallback(() => setPrivacyOpen(true), []);
    const handleTermsClose = React.useCallback(() => setTermsOpen(false), []);
    const handlePrivacyClose = React.useCallback(() => setPrivacyOpen(false), []);
    React.useEffect(() => {
        async function loadMarkdown() {
            try {
                setTermsMarkdown(null);
                setPrivacyMarkdown(null);
                const terms = await Axios.get(
                    `${process.env.MEDIA_URL}/tenants/${merchantId}/app_assets/terms`
                );
                const privacy = await Axios.get(
                    `${process.env.MEDIA_URL}/tenants/${merchantId}/app_assets/privacy`
                );
                setTermsMarkdown(terms.status === 200 ? terms.data : t('AUTH_TERMS_TERMS_LOADING_FAILED'));
                setPrivacyMarkdown(
                    privacy.status === 200 ? privacy.data : t('AUTH_TERMS_PRIVACY_LOADING_FAILED')
                );
            } catch (err) {
                setTermsMarkdown(t('AUTH_TERMS_TERMS_LOADING_FAILED'));
                setPrivacyMarkdown(t('AUTH_TERMS_PRIVACY_LOADING_FAILED'));
            }
        }
        loadMarkdown();
    }, [merchantId, t]);
    return (
        <React.Fragment>
            <Typography align="center" variant={variant}>
                {t('BY_CONTINUING')}{' '}
                <span className={classes.link} onClick={handleTermsClick}>
                    {t('AUTH_TERMS_TERMS_AND_CONDITIONS')}
                </span>{' '}
                {t('AUTH_TERMS_AND')}{' '}
                <span className={classes.link} onClick={handlePrivacyClick}>
                    {t('AUTH_TERMS_PRIVACY_POLICY')}
                </span>
            </Typography>
            <Dialog fullScreen open={termsOpen} TransitionComponent={Slide} TransitionProps={transitionProps}>
                <MuiAppBar title={t('ABOUT_TERMS')} icon={Close} onBack={handleTermsClose} disabledMenu />
                {termsMarkdown === null && <Throbber text={t('GENERAL_LOADING')} />}
                {termsMarkdown !== null && (
                    <Box className={classes.markdownContainer}>
                        <Markdown source={termsMarkdown} />
                    </Box>
                )}
            </Dialog>
            <Dialog
                fullScreen
                open={privacyOpen}
                TransitionComponent={Slide}
                TransitionProps={transitionProps}
            >
                <MuiAppBar title={t('ABOUT_PRIVACY')} icon={Close} onBack={handlePrivacyClose} disabledMenu />
                {privacyMarkdown === null && <Throbber text={t('GENERAL_LOADING')} />}
                {privacyMarkdown !== null && (
                    <Box className={classes.markdownContainer}>
                        <Markdown source={privacyMarkdown} />
                    </Box>
                )}
            </Dialog>
        </React.Fragment>
    );
};
