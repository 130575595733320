import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from 'src/hooks/useQuery';
import { EActionType } from 'components/activity/enums';
import {
    IAction,
    IActionData,
    IActionPerkRedeemedMetadata,
    IActionRewardBurnedMetadata,
    IActionRewardEarnedMetadata
} from 'components/activity/models';
import { getLocalMerchantId } from 'components/settings/localStore';
import { getOnCloseRedirectUrl } from 'components/user/localAuth';
import { UserCard } from 'components/user/model/User';
import { ELineIconName } from 'lib/LineIcon/enum';
import { LineIconProvider } from 'lib/LineIcon/LineIconProvider';
import { useCurrencyString } from 'lib/useCurrencyString';
import { ApplicationState } from 'store/store';
import { InfiniteLoader } from 'ui/InfiniteLoader';
import { ActionItem } from './ActionItem';
import { OrderDetails } from './OrderDetails';
import { ResendReceipt } from './ResendReceipt';
import { ELoyaltyTemplate } from 'components/awards/enums';
import { isEmptyString } from 'lib/typeInference';

interface IProps {
    list: IAction[];
    loadMore: () => any;
    isLoading: boolean;
    hasNext: boolean;
}

const activityTypeToIconMap: Partial<Record<EActionType, string>> = {
    [EActionType.CARD_CREATED]: ELineIconName.CARD,
    [EActionType.CARD_DELETED]: ELineIconName.CARD,
    [EActionType.REWARD_EARNED]: ELineIconName.STAR,
    [EActionType.REWARD_BURNED]: ELineIconName.STAR,
    [EActionType.REDEEM_PERK]: ELineIconName.STAR,
    [EActionType.CAUSE_GIFT]: ELineIconName.HEART,
    [EActionType.USER_CREATED]: ELineIconName.PERSON_ADD,
    [EActionType.USER_ACTIVATED]: ELineIconName.PERSON_ADD,
    [EActionType.USER_CREDITED]: ELineIconName.MONEY,
    [EActionType.REFUND]: ELineIconName.MONEY,
    [EActionType.TOPUP_REFUNDED]: ELineIconName.MONEY,
    [EActionType.TOPUP]: ELineIconName.MONEY,
    [EActionType.ORDER_COMPLETED]: ELineIconName.CUP,
    [EActionType.ORDER_USER_BILLED]: ELineIconName.MONEY,
    [EActionType.ORDER_PAYMENT_RECEIVED]: ELineIconName.MONEY,
    [EActionType.VOUCHER_REDEEMED]: ELineIconName.TICKET,
    [EActionType.ORDER_USER_JOINED]: ELineIconName.PERSON_ADD,
    [EActionType.CREDENTIAL_VERIFIED]: ELineIconName.PERSON_ADD,
    [EActionType.REFERAL_CLAIMED_REFERREE]: ELineIconName.HANDSHAKE,
    [EActionType.ORDER_PAYMENT_REFUNDED]: ELineIconName.MONETIZATION
};

const activityTypeToTitleStringMap: Partial<Record<EActionType, string>> = {
    [EActionType.CARD_CREATED]: 'ACTIVITY_ITEM_CARD_ADDED',
    [EActionType.CARD_DELETED]: 'ACTIVITY_ITEM_CARD_REMOVED',
    [EActionType.REWARD_EARNED]: 'ACTIVITY_ITEM_REWARD_EARNED',
    [EActionType.REWARD_BURNED]: 'ACTIVITY_ITEM_REWARD_BURNED',
    [EActionType.REDEEM_PERK]: 'ACTIVITY_ITEM_PERK_REDEEMED',
    [EActionType.CAUSE_GIFT]: 'ACTIVITY_ITEM_CAUSE_GIFT',
    [EActionType.USER_CREATED]: 'ACTIVITY_ITEM_ACCOUNT_CREATED',
    [EActionType.USER_ACTIVATED]: 'ACTIVITY_ITEM_ACCOUNT_ACTIVATED',
    [EActionType.USER_CREDITED]: 'ACTIVITY_ITEM_CREDIT',
    [EActionType.REFUND]: 'ACTIVITY_ITEM_REFUND',
    [EActionType.TOPUP_REFUNDED]: 'ACTIVITY_ITEM_TOPUP_REFUND',
    [EActionType.ORDER_COMPLETED]: 'ACTIVITY_ITEM_ORDER_COMPLETED',
    [EActionType.ORDER_USER_BILLED]: 'ACTIVITY_ITEM_USER_BILLED',
    [EActionType.ORDER_PAYMENT_RECEIVED]: 'ACTIVITY_ITEM_PAYMENT_RECEIVED',
    [EActionType.VOUCHER_REDEEMED]: 'ACTIVITY_ITEM_VOUCHER_REDEEMED',
    [EActionType.ORDER_USER_JOINED]: 'ACTIVITY_ITEM_USER_JOINED',
    [EActionType.CREDENTIAL_VERIFIED]: 'ACTIVITY_ITEM_CREDENTIAL_VERIFIED',
    [EActionType.REFERAL_CLAIMED_REFERREE]: 'ACTIVITY_ITEM_REFERRAL_CLAIMED_REFEREE',
    [EActionType.REFERAL_CLAIMED_REFERRER]: 'ACTIVITY_ITEM_REFERRAL_CLAIMED_REFERER',
    [EActionType.ORDER_PAYMENT_REFUNDED]: 'ACTIVITY_ITEM_PAYMENT_REFUNDED',
    [EActionType.ORDER_CREATED]: 'ACTIVITY_ITEM_ORDER_CREATED',
    [EActionType.MEMBER_ADDED]: 'ACTIVITY_ITEM_MEMBER_ADDED',
    [EActionType.MEMBER_REMOVED]: 'ACTIVITY_ITEM_MEMBER_REMOVED',
    [EActionType.USER_ACTIVATION_RESENT]: 'ACTIVITY_ITEM_USER_ACTIVATION_RESENT',
    [EActionType.PURCHASE]: 'ACTIVITY_ITEM_PURCHASE',
    [EActionType.USER_CHANGED]: 'ACTIVITY_ITEM_USER_CHANGED'
};

export const ActivityList: React.FC<IProps> = ({ list, loadMore, isLoading, hasNext }) => {
    const { t } = useTranslation();
    const { push } = useHistory();

    const [selectedAction, setSelectedAction] = React.useState<IAction | null>(null);
    const actionNotifications = useSelector(
        (state: ApplicationState) => state.settings.settings?.actionNotifications
    );
    const getCurrencyString = useCurrencyString();

    const filteredList = React.useMemo(() => list.filter(({ type }) => EActionType[type]), [list]);

    const orderId = useQuery('orderId');
    const orderRelatedActionId = React.useMemo(
        () =>
            list.find(
                action =>
                    (action.type === EActionType.ORDER_USER_BILLED ||
                        action.type === EActionType.ORDER_COMPLETED) &&
                    action.metadata.order &&
                    action.metadata.order.id.toString() === orderId
            )?._id || undefined,
        [list, orderId]
    );
    const [selectedActionId, setSelectedActionId] = React.useState<string | undefined>(orderRelatedActionId);

    const getRewardOverviewString = React.useCallback(
        (template: ELoyaltyTemplate, count: number, date: string, title?: string) => {
            let content = '';
            const trimmedTitle = (title ?? '').trim();
            switch (template) {
                case ELoyaltyTemplate.STAMP_CARD:
                    content = `${!isEmptyString(trimmedTitle) ? `${trimmedTitle} - ` : ''}${count} stamp${
                        count === 1 ? '' : 's'
                    }`;
                    break;
                case ELoyaltyTemplate.PSEUDO_CURRENCY:
                    content = `${!isEmptyString(trimmedTitle) ? `${trimmedTitle} - ` : ''}${count} point${
                        count === 1 ? '' : 's'
                    }`;
                    break;
                default:
                    content =
                        trimmedTitle ??
                        `${count} ${t('REWARD_CURRENCY_UNIT', {
                            count
                        })}`;
            }
            return `${content} | ${date}`;
        },
        [t]
    );

    const getActionSubtitleArgs = React.useCallback(
        (action: IAction) => {
            if (action.metadata) {
                const date = moment(action.when.timestamp).fromNow();
                switch (action.type) {
                    case EActionType.CARD_CREATED:
                    case EActionType.CARD_DELETED: {
                        const { type: cardType }: UserCard = action.metadata;
                        return `${cardType} | ${date}`;
                    }
                    case EActionType.REWARD_EARNED: {
                        const { pointsAdded, template, title }: IActionRewardEarnedMetadata = action.metadata;
                        return getRewardOverviewString(template, pointsAdded, date, title);
                    }
                    case EActionType.REWARD_BURNED: {
                        const { loyaltyBurned, template, title }: IActionRewardBurnedMetadata =
                            action.metadata;
                        return getRewardOverviewString(template, loyaltyBurned, date, title);
                    }
                    case EActionType.REDEEM_PERK: {
                        const { pointsRedeemed, template, title }: IActionPerkRedeemedMetadata =
                            action.metadata;
                        return getRewardOverviewString(template, pointsRedeemed, date, title);
                    }
                    case EActionType.CAUSE_GIFT: {
                        const { causeTitle }: { causeTitle: string } = action.metadata;
                        return `${causeTitle} | ${date}`;
                    }
                    case EActionType.USER_CREATED: {
                        const { provider }: { provider: string } = action.metadata;
                        return `${provider} | ${date}`;
                    }
                    case EActionType.USER_ACTIVATED: {
                        const { username }: { username: string } = action.metadata;
                        return `${username} | ${date}`;
                    }
                    case EActionType.USER_CREDITED: {
                        const {
                            value: { amount }
                        }: { value: { amount: number; currency: string } } = action.metadata;
                        return `${amount} ${t('REWARD_CURRENCY_UNIT', { count: amount })} | ${date}`;
                    }
                    case EActionType.TOPUP_REFUNDED:
                    case EActionType.TOPUP:
                    case EActionType.ORDER_USER_BILLED:
                    case EActionType.ORDER_PAYMENT_RECEIVED:
                    case EActionType.ORDER_COMPLETED: {
                        const {
                            value: { amount }
                        }: { value: { amount: number; currency: string } } = action.metadata;
                        return `${getCurrencyString(amount)} | ${date}`;
                    }
                    case EActionType.ORDER_USER_JOINED:
                    case EActionType.VOUCHER_REDEEMED:
                    case EActionType.REFERAL_CLAIMED_REFERRER:
                    case EActionType.CREDENTIAL_VERIFIED:
                    case EActionType.REFERAL_CLAIMED_REFERREE: {
                        return `${date}`;
                    }
                    default:
                        return '';
                }
            }
            return '';
        },
        [getCurrencyString, getRewardOverviewString, t]
    );

    const handleActionClick = React.useCallback(
        (action: IAction) => () => {
            setSelectedAction(action);
        },
        []
    );

    const renderActionItem = React.useCallback(
        (action: IAction) => {
            const isResendAvailable =
                actionNotifications &&
                ((action?.type === EActionType.ORDER_USER_BILLED &&
                    actionNotifications[EActionType.ORDER_USER_BILLED]?.email) ||
                    (action?.type === EActionType.ORDER_COMPLETED &&
                        actionNotifications[EActionType.ORDER_COMPLETED]?.email));
            return (
                <ActionItem
                    key={action._id}
                    icon={activityTypeToIconMap[action.type]}
                    title={t(activityTypeToTitleStringMap[action.type] || 'ACTIVITY_ITEM_UNKNOWN')}
                    subtitle={getActionSubtitleArgs(action)}
                    onClick={isResendAvailable ? handleActionClick(action) : undefined}
                />
            );
        },
        [actionNotifications, getActionSubtitleArgs, handleActionClick, t]
    );
    const handleListGetter = React.useCallback((data: IActionData) => data.items, []);

    const handleResendDialogClose = React.useCallback(() => {
        setSelectedActionId(undefined);
        if (orderRelatedActionId) {
            const merchantId = getLocalMerchantId();
            if (merchantId) {
                const redirectUrl = getOnCloseRedirectUrl(merchantId);
                if (redirectUrl) {
                    push(redirectUrl);
                }
            }
        }
    }, [orderRelatedActionId, push]);

    const handleCloseOrderDetails = React.useCallback(() => {
        setSelectedAction(null);
    }, []);

    return (
        <LineIconProvider>
            <InfiniteLoader<IAction, IActionData>
                list={filteredList}
                loadMore={loadMore}
                dataListGetter={handleListGetter}
                isLoading={isLoading}
                hasNext={hasNext}
            >
                {(actions: IAction[]) =>
                    actions.filter(action => EActionType[action.type]).map(renderActionItem)
                }
            </InfiniteLoader>
            {selectedActionId && (
                <ResendReceipt
                    onClose={handleResendDialogClose}
                    actionId={selectedActionId}
                    isOpen={!!selectedActionId}
                    onReceiptSent={handleResendDialogClose}
                    fullScreen={false}
                />
            )}
            {!!selectedAction && (
                <OrderDetails
                    action={selectedAction}
                    onClose={handleCloseOrderDetails}
                    isOpen={!!selectedAction}
                    openResendReceipt={setSelectedActionId}
                />
            )}
        </LineIconProvider>
    );
};
