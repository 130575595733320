import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { IPointAward } from 'components/awards/models';
import { QrCode } from 'components/awards/qr-code/QrCode';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

interface IProps {
    award: IPointAward;
    onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            color: theme.palette.text.primary
        },
        colorTint: {
            color: theme.palette.text.hint
        }
    })
);

export const AwardDetailsDialog: React.FC<IProps> = ({ onClose, award }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const [isTermsOpen, setIsTermsOpen] = React.useState<boolean>(false);

    const handleTermsAndConditionsClick = React.useCallback(() => {
        setIsTermsOpen(true);
    }, []);
    const handleTermsAndConditionsClose = React.useCallback(() => {
        setIsTermsOpen(false);
    }, []);

    const handleClose = React.useCallback(() => {
        onClose();
        handleTermsAndConditionsClose();
    }, [handleTermsAndConditionsClose, onClose]);

    return (
        <Dialog onClose={handleClose} open={!!award} fullWidth>
            {!isTermsOpen ? (
                <>
                    <DialogTitle>
                        <Typography align="center">{award?.title}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography className={classes.dialogContent}>
                                {award?.visualisation.description}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleTermsAndConditionsClick} className={classes.colorTint}>
                            {t('AUTH_TERMS_TERMS_AND_CONDITIONS')}
                        </Button>
                    </DialogActions>
                    {settings?.offlineCheckinEnabled && <QrCode award={award} />}
                </>
            ) : (
                <>
                    <DialogTitle>
                        <Typography align="center">{t('AUTH_TERMS_TERMS_AND_CONDITIONS')}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography className={classes.dialogContent}>{award?.terms}</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleTermsAndConditionsClose} className={classes.colorTint}>
                            {t('AWARDS_TERMSANDCONDITIONS_ACTIONS_BACK')}
                        </Button>
                    </DialogActions>
                    {settings?.offlineCheckinEnabled && <QrCode award={award} />}
                </>
            )}
        </Dialog>
    );
};
