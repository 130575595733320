import { Box, makeStyles, Typography } from '@material-ui/core';
import { Image } from 'lib/Image';
import { BaseRouteParams } from 'pages/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const useStyles = makeStyles({
    image: {
        width: '100%',
        height: 'auto'
    }
});

export const NoAwardsPlaceholder: React.FC = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [error, setError] = React.useState(false);
    const { merchantId } = useParams<BaseRouteParams>();
    const handleError = React.useCallback(() => {
        setError(true);
    }, []);
    return (
        <Box flex={1}>
            {!error && (
                <Image
                    className={classes.image}
                    src={`${process.env.MEDIA_URL}/tenants/${merchantId}/app_assets/no_awards_placeholder`}
                    onError={handleError}
                />
            )}
            {error && <Typography align="center">{t('AWARDS_LIST_EMPTY')}</Typography>}
        </Box>
    );
};
