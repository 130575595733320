import React from 'react';
import { AppBar, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { PAY_MAX_WIDTH } from 'pages/pay';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.main,
            padding: theme.spacing(1, 2, 0, 2),
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            maxWidth: PAY_MAX_WIDTH
        },
        marginBlock: {
            height: theme.spacing(16),
            flexShrink: 0,
            width: '100%'
        },
        small: {
            height: theme.spacing(9)
        },
        large: {
            height: theme.spacing(21)
        }
    })
);

interface PayHeaderProps {
    variant?: 'default' | 'small' | 'large';
}

export const PayHeader: React.FC<PayHeaderProps> = ({ children, variant = 'default' }) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <div
                className={clsx(classes.marginBlock, {
                    [classes.small]: variant === 'small',
                    [classes.large]: variant === 'large'
                })}
            />
            <AppBar position="fixed" elevation={0} className={classes.appBar}>
                {children}
            </AppBar>
        </React.Fragment>
    );
};
